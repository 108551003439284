import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@app/core/constants/constants';
import { AccountDocumentType, ActiveInactiveStatus, AvailUnavailStatus, AwardStatus, AwardStatusAction, AwardStatusList, AwardSubmittedCapacityStatus, CallerScreen, CapacityListStatusAction, CapacityStatus, CapacityStatusAction, CarrierStatus, CreditRequestStatus, CustomerStatus, EdiLoadTenderListStatus, FacilityStatus, FileExtension, IncidentStatus, ListActionType, LoadCarrierBidStatus, LoadStatus, Module, PermissionCode, PermissionModuleCode, PurchaseJournalStatus, RateConfirmationStatus } from '@app/core/constants/enums';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { ResponseModel } from '@app/core/models/api.response.model';
import { UserDetailModel } from '@app/core/models/user-detail.model';
import { ToasterService } from '@app/core/services/toaster.service';
import { AwardPotentialCapacityModel, AwardStatusUpdateModel } from '@app/modules/award/award.model';
import { AwardService } from '@app/modules/award/award.service';
import { AwardValidateComponent } from '@app/modules/award/shared/award-validate/award-validate.component';
import { CapacityAddComponent } from '@app/modules/capacity/capacity-add/capacity-add.component';
import { CapacityDetailModel, CapacityStatusUpdateModel, SubmittedCapacityModel } from '@app/modules/capacity/capacity.model';
import { CapacityService } from '@app/modules/capacity/capacity.service';
import { CapacityValidateComponent } from '@app/modules/capacity/shared/capacity-validate/capacity-validate.component';
import { LoadService } from '@app/modules/load/load.service';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { distinctUntilChanged } from 'rxjs';
import { String } from 'typescript-string-operations';

@Component({
  selector: 'app-list-actions',
  templateUrl: './list-actions.component.html',
  styles: [
  ]
})
export class ListActionsComponent {
  params!: ICellRendererParams | any;
  module = PermissionModuleCode;
  customerStatusEnum: typeof CustomerStatus = CustomerStatus;
  loadBidStatus = LoadCarrierBidStatus;
  creditRequestStatusEnum = CreditRequestStatus;
  carrierStatusEnum: typeof CarrierStatus = CarrierStatus;
  fileExtensionEnum: typeof FileExtension = FileExtension;
  availUnavailStatus: typeof AvailUnavailStatus = AvailUnavailStatus;
  incidentStatusEnum: typeof IncidentStatus = IncidentStatus;
  ediLoadTenderStatus: typeof EdiLoadTenderListStatus = EdiLoadTenderListStatus;
  rateConfirmationStatus: typeof RateConfirmationStatus = RateConfirmationStatus;
  purchaseJournalStatus: typeof PurchaseJournalStatus = PurchaseJournalStatus;
  accountDocumentType: typeof AccountDocumentType = AccountDocumentType;
  activeInactiveStatus: typeof ActiveInactiveStatus = ActiveInactiveStatus;
  ediProcessFlag!: boolean;
  loadStatus: typeof LoadStatus = LoadStatus;
  @ViewChild('overlayPanel') overlayPanel!: OverlayPanel;
  @ViewChild('editPanel') editPanel!: OverlayPanel;
  permission = PermissionCode;
  facilityStatus: typeof FacilityStatus = FacilityStatus;
  facilityButtonText: typeof ActiveInactiveStatus = ActiveInactiveStatus;
  listActionType = ListActionType;
  awardListStatus: typeof AwardStatusList = AwardStatusList;
  awardStatusAction: typeof AwardStatusAction = AwardStatusAction;
  dialogRef!: DynamicDialogRef;
  capacityStatus: typeof CapacityStatus = CapacityStatus;
  capacityButtonText: typeof ActiveInactiveStatus = ActiveInactiveStatus;
  awardSubmittedCapacityStatus: typeof AwardSubmittedCapacityStatus = AwardSubmittedCapacityStatus;
  awardStatus = AwardStatus;
  capcityStatusAction: typeof CapacityListStatusAction = CapacityListStatusAction;
  callerScreen = CallerScreen;
  constructor(
    private readonly loadService: LoadService,
    private readonly awardService: AwardService,
    private readonly confirmationService: ConfirmationService,
    private readonly toastService: ToasterService,
    private readonly dialogService: DialogService,
    private readonly capcityService: CapacityService,
    private readonly router: Router,
  ) {
    this.loadService.overlayPanelSubject$.pipe(distinctUntilChanged()).subscribe(() => {
      this.overlayPanel?.hide();
    });
    this.loadService.editPanelSubject$.pipe(distinctUntilChanged()).subscribe(() => {
      this.editPanel?.hide();
    });
  }

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  edit() {
    this.params.onEditClick(this.params?.node?.data);
  }

  editInnerException() {
    this.params.onEditClickInnerException(this.params?.node?.data);
  }

  editPayLoad() {
    this.params.onEditClickPayLoad(this.params?.node?.data);
  }

  viewPdf() {
    this.params.onPdfViewer(this.params?.node?.data);
  }

  markDNU() {
    this.params.onMarkDNU(this.params?.node?.data);
  }

  markActive() {
    this.params.onMarkActive(this.params?.node?.data);
  }

  updateStatus() {
    this.params.onUpdateStatusClick(this.params?.node?.data);
  }

  setDefault() {
    this.params.onSetAsDefaultClick(this.params?.node?.data);
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }

  assignRep() {
    this.params.onAssignSalesRep(this.params?.node?.data);
  }

  remove() {
    this.params.onDeleteClick(this.params?.node?.data);
  }

  approveRequest() {
    this.params.onApproveDialogClick(this.params?.node?.data);
  }

  rejectRequest() {
    this.params.onRejectDialogClick(this.params?.node?.data);
  }

  approveBidRequest(): void {
    this.params.onRejectApproveBidClick(this.params?.node?.data);
  }

  rejectBidRequest(): void {
    this.params.onRejectRejectBidClick(this.params?.node?.data);
  }

  viewDocument(): void {
    this.params.onViewDocumentClick(this.params?.node?.data);
  }

  deleteDocument(): void {
    this.params.onDeleteDocumentClick(this.params?.node?.data);
  }

  downloadDocument(): void {
    this.params.onDownloadDocumentClick(this.params?.node?.data);
  }

  onViewerClick(): void {
    this.params.onViewerClick(this.params?.node?.data);
  }

  revert() {
    this.params.onRevertClick(this.params?.node?.data);
  }

  editRecord() {
    this.params.onEditClick(this.params?.node?.data);
  }

  viewDocuments() {
    this.params.onDocumentClick(this.params?.node?.data);
  }

  viewInvoice() {
    this.params.onInvoiceClick(this.params?.node?.data);
  }

  resolveIncident(response: any) {
    this.params.onResolveClick(response);
  }

  resolutionInformation() {
    this.params.onInformationClick(this.params?.node?.data);
  }

  assignEdiTender() {
    this.params.onAssignEdiTender(this.params?.node?.data);
  }

  processEdiTender() {
    this.params.processEdiTender(this.params?.node?.data);
  }

  snooze(): void {
    this.params.onSnoozeClick(this.params?.node?.data);
  }
  highVisibility(): void {
    this.params.onHighVisibilityClick(this.params?.node?.data);
  }
  manageCheckCall(): void {
    this.params.onManageCheckCallClick(this.params?.node?.data);
  }
  manageIncidents(): void {
    this.params.onManageIncidentsClick(this.params?.node?.data);
  }

  onApRevert(): void {
    this.params.onRevertClick(this.params?.node?.data);
  }

  onApEdit() {
    this.params.onEditClick(this.params?.node?.data);
  }

  onApDocument() {
    this.params.onDocumentClick(this.params?.node?.data);
  }



  onAuditReviewEdit(): void {
    this.params.onAuditReviewEdit(this.params?.node?.data);
  }

  onRelese() {
    this.params.onRelese(this.params?.node?.data);
  }

  onAudtitReviewDocument() {
    this.params.onAudtitReviewDocument(this.params?.node?.data);
  }

  viewEDIPayload(): void {
    this.params.onViewClick(this.params?.node?.data);
  }
  assignViewsClick() {
    this.params.assignViewsClick(this.params?.node?.data);
  }

  toggleOverlayPanel(event: Event, overlayPanel: any): void {
    this.loadService.setOverlayPanel(this.params?.node?.data.loadIncidentId);
    overlayPanel.toggle(event);
  }

  ediUpdateIcon() {
    this.params.onEdiUpdateIconClick(this.params?.node?.data);
  }

  assignCapacity() {
    this.params.onAssignClick(this.params?.node?.data);
  }

  loadDocument() {
    this.params.onLoadDocumentsClick(this.params?.node?.data);
  }

  loadActivitiesClick() {
    this.params.onLoadActivitiesClick(this.params?.node?.data);
  }

  processEdi(ediLoadTenderStatus: any) {
    if (ediLoadTenderStatus && ediLoadTenderStatus === this.ediLoadTenderStatus.Pending) {
      this.ediProcessFlag = true;
    }
    return this.ediProcessFlag;
  }

  isEDIProcessVisible(): boolean {
    return LoginHelper.IsModuleAccessible(PermissionModuleCode.EDIManagement, PermissionCode.AcceptLoadTender) ||
      LoginHelper.IsModuleAccessible(PermissionModuleCode.EDIManagement, PermissionCode.DeclineLoadTender);
  }

  isViewLoadDocumentsPermitted(): boolean {
    return LoginHelper.IsModuleAccessible(PermissionModuleCode.DocumentManagement, PermissionCode.ViewDocumentonLoad);
  }

  getCurrentUser(): UserDetailModel {
    return LoginHelper.GetUserDetail();
  }

  syncPaymentClick() {
    this.params.onSyncPaymentClick(this.params?.node?.data);
  }

  downloadReportDocument(): void {
    this.params.onDownloadDocumentClick(this.params?.node?.data);
  }


  hasImplicitePermission(params: any, explicitPermission: boolean): boolean {
    return explicitPermission ||
      params?.loggedInUser === params?.node?.data.accountManagerId ||
      params?.loggedInUser ===
      params?.node?.data.carrierOperationsRepresentativeId ||
      params?.loggedInUser ===
      params?.node?.data.customerOperationsRepresentativeId ||
      params?.loggedInUser === params?.node?.data.managerId ||
      params?.loggedInUser === params?.node?.data.accountOwnerId ||
      params?.loggedInUser === params?.node?.data.deskOwnerId ||
      params?.loggedInUser === params?.node?.data.executiveSponsorId ||
      params?.loggedInUser === params?.node?.data.carrierRepId
      ? true
      : false;
  }

  onIntergrationSyncClick() {
    this.params.onIntergrationSyncClick(this.params?.node?.data);
  }

  renewCarrierPreferredLane() {
    this.params.renewCarrierPreferredLane(this.params?.node?.data);
  }

  onFacilityStatusChange(): void {
    this.params.onFacilityStatusChange(this.params?.node?.data);
  }

  onFacilityCustomerStatusChange(): void {
    this.params.onFacilityCustomerStatusChange(this.params?.node?.data);
  }

  onFacilityCustomerEdit(): void {
    this.params.onFacilityCustomerEdit(this.params?.node?.data);
  }

  onUpdateAwardStatus(awardStatusAction: string): void {
    const awardDetail = this.params?.node?.data;
    if (awardStatusAction === AwardStatusAction.Activate || awardStatusAction === AwardStatusAction.Inactivate) {
      this.awardService.getAwardLockDetail(awardDetail.awardDetailId).subscribe((response) => {
        if (response.data && response.data.lockedBy !== LoginHelper.GetUserDetail().userId) {
          this.toastService.showWarnMessage(String.format(Constants.lockProfileWarning, response.data.userName));
        } else {
          this.updateAwardStatus(awardStatusAction);
        }
      });
    } else {
      this.updateAwardStatus(awardStatusAction);
    }
  }

  updateAwardStatus(awardStatusAction: string): void {
    const awardDetail = this.params?.node?.data;
    if (awardStatusAction === AwardStatusAction.Approve || awardStatusAction === AwardStatusAction.Resubmit || awardStatusAction === AwardStatusAction.Activate
      || awardStatusAction === AwardStatusAction.Pending) {
      this.confirmationService.confirm({
        message: awardStatusAction === AwardStatusAction.Pending ?
          String.format(Constants.awardUpdateStatusConfirmation, AwardStatusAction.Approve.toLowerCase(), Constants.awardBid.toLowerCase()) :
          String.format(Constants.awardUpdateStatusConfirmation, awardStatusAction.toLowerCase(), this.module.Award),
        header: Constants.confirmation,
        icon: Constants.updateStatusDialogIcon,
        accept: () => {
          const awardUpdateStatusRequest: AwardStatusUpdateModel = {
            awardDetailId: awardDetail.awardDetailId,
            awardStatus: awardStatusAction,
            modifiedBy: LoginHelper.GetUserDetail().userId,
            modifiedByUser: LoginHelper.GetUserDetail().userName,
            customerId: awardDetail.customerId
          };
          this.awardService.updateAwardStatus(awardUpdateStatusRequest, this.params?.callerScreen).subscribe((response) => {
            if (response.data) {
              this.toastService.showSuccessMessage(response.userMessage);
              this.params.onUpdateAwardStatus(true);
            }
          });
        }
      });
    }
    if (awardStatusAction === AwardStatusAction.Reject || awardStatusAction === AwardStatusAction.Inactivate || awardStatusAction === AwardStatusAction.Lost) {
      this.dialogRef = this.dialogService.open(AwardValidateComponent, {
        width: '25vw',
        header: awardStatusAction === AwardStatusAction.Lost ?
          String.format(Constants.actionStatusPopupHeader, AwardStatusAction.Reject, Constants.awardBid) :
          String.format(Constants.actionStatusPopupHeader, awardStatusAction, Module.Award),
        closable: false,
        data: {
          awardDetailId: awardDetail.awardDetailId,
          awardStatusAction: awardStatusAction,
          buttonText: awardStatusAction === AwardStatusAction.Lost ? AwardStatusAction.Reject : awardStatusAction,
          awardStatusReasonList: this.params.awardStatusReasonList,
          callerScreen: this.params?.callerScreen,
          customerId: awardDetail.customerId
        }
      });

      this.dialogRef.onClose.subscribe((response: ResponseModel<number>) => {
        if (response) {
          this.toastService.showSuccessMessage(response.userMessage);
          this.params.onUpdateAwardStatus(true);
        }
      });
    }
  }

  onUpdateCapcityStatus(capacityStatusAction: string): void {
    const capacityDetail = this.params?.node?.data;
    this.capcityService.getCapacityLockDetail(capacityDetail.capacityDetailId).subscribe((response) => {
      if (response.data && response.data.lockedBy !== LoginHelper.GetUserDetail().userId) {
        this.toastService.showWarnMessage(String.format(Constants.lockProfileWarning, response.data.userName));
      } else {
        this.updateCapacityStatus(capacityStatusAction);
      }
    });
  }

  updateCapacityStatus(capacityStatusAction: string): void {
    const capacityDetail = this.params?.node?.data;
    if (capacityStatusAction === CapacityListStatusAction.Inactivate) {
      this.dialogRef = this.dialogService.open(CapacityValidateComponent, {
        width: '25vw',
        header: String.format(Constants.actionStatusPopupHeader, capacityStatusAction, Module.Capacity),
        closable: false,
        data: {
          capacityDetailId: capacityDetail.capacityDetailId,
          capacityStatusAction: capacityStatusAction,
          capcityStatusReasonList: this.params.capcityStatusReasonList,
          callerScreen: this.params?.callerScreen === CallerScreen.CarrierProfile ? CallerScreen.CarrierProfile : CallerScreen.CapacityListing,
          carrierId: capacityDetail.carrierId
        }
      });

      this.dialogRef.onClose.subscribe((response: ResponseModel<number>) => {
        if (response) {
          this.toastService.showSuccessMessage(response.userMessage);
          this.params.onCapacityUpdateStatus(true);
        }
      });
    }
    if (capacityStatusAction === CapacityListStatusAction.Activate) {
      this.confirmationService.confirm({
        message: String.format(Constants.activateStatusConfirmation, Module.Capacity),
        header: Constants.confirmation,
        icon: Constants.updateStatusDialogIcon,
        accept: () => {
          const capacityUpdateStatusRequest: CapacityStatusUpdateModel = {
            capacityDetailId: capacityDetail.capacityDetailId,
            capacityStatus: capacityStatusAction,
            modifiedBy: LoginHelper.GetUserDetail().userId,
            modifiedByUser: LoginHelper.GetUserDetail().userName,
            carrierId: capacityDetail.carrierId
          };
          this.capcityService.updateCapacityStatus(capacityUpdateStatusRequest, this.params?.callerScreen === CallerScreen.CarrierProfile ? CallerScreen.CarrierProfile : CallerScreen.CapacityListing).subscribe((response) => {
            if (response.data) {
              this.toastService.showSuccessMessage(response.userMessage);
              this.params.onCapacityUpdateStatus(true);
            }
          });
        }
      });
    }
  }

  openAddCapacityDialog(): void {
    const awardDetail = this.params?.node?.data;
    this.dialogRef = this.dialogService.open(CapacityAddComponent, {
      width: '66vw',
      header: String.format(Constants.actionStatusPopupHeader, 'Submit', Module.Capacity),
      closable: true,
      data: {
        isAwardCapacity: true,
        awardDetailId: awardDetail.awardDetailId,
        customerId: awardDetail.customerId,
        callerScreen: this.params?.callerScreen === CallerScreen.CustomerProfile ? CallerScreen.CustomerProfile : CallerScreen.AwardListing
      }
    });
    this.dialogRef.onClose.subscribe((response: ResponseModel<CapacityDetailModel>) => {
      if (response) {
        this.toastService.showSuccessMessage(response.userMessage);
      }
    });
  }

  onSubmitCapacityOnPotentialAward(): void {
    this.params.onSubmitCapacityOnPotentialAward(this.params?.node?.data);
  }

  onApproveSubmittedCapacity(): void {
    this.params.onApproveSubmittedCapacity(this.params?.node?.data, CapacityStatusAction.Approve);
  }

  onRejectSubmittedCapacity(): void {
    this.params.onRejectSubmittedCapacity(this.params?.node?.data, CapacityStatusAction.Reject);
  }

  onResubmitSubmittedCapacity(): void {
    this.params.onResubmitSubmittedCapacity(this.params?.node?.data, CapacityStatusAction.Resubmit);
  }

  onNotifyRep(): void {
    const capacityDetail = this.params?.node?.data as AwardPotentialCapacityModel;
    this.params.onNotifyRep(capacityDetail);
  }

  onRemoveWaterfallCapacity(): void {
    const capacityDetail = this.params?.node?.data as SubmittedCapacityModel;
    this.params.onRemoveWaterfallCapacity(capacityDetail);
  }

  cloneLoadClick(): void {
    this.params.onCloneLoad(this.params?.node?.data);
  }

  downloadManageFuelSurchargeDocument(): void {
    this.params.onDownloadDocumentClick(this.params?.node?.data);
  }

  downloadBidDocument(): void {
    this.params.onDownloadBidDocumentClick(this.params?.node?.data);
  }

  openCloneCapacityDialog(): void {
    this.capcityService.getCapacityDetail(this.params?.node?.data?.capacityDetailId).subscribe((response) => {
      if (response.data) {
        const capacityDetail = response.data;
        this.dialogRef = this.dialogService.open(CapacityAddComponent, {
          width: '65vw',
          header: String.format(Constants.actionStatusPopupHeader, 'Clone', Module.Capacity),
          closable: true,
          data: {
            isCloneCapacity: true,
            cloneCapacityDetail: capacityDetail,
            callerScreen: CallerScreen.CapacityListing
          }
        });
        this.dialogRef.onClose.subscribe((response: ResponseModel<CapacityDetailModel>) => {
          if (response) {
            localStorage.setItem(Constants.showSuccessMessage, 'true');
            this.router.navigate([]).then(() => { window.open(`capacity/edit/${response.data?.capacityDetailId}`, '_blank'); });
          }
        });
      }
    });
  }

  onResendCheckCallClick(): void {
    this.params.onResendCheckCallClick(this.params?.node?.data);
  }

  loadQuickView(): void {
    this.params.onLoadQuickView(this.params?.node?.data);
  }

  toggleEditPanel(event: Event, editPanel: any): void {
    this.loadService.setEdiPanel(this.params?.node?.data.documentId);
    editPanel.toggle(event);
  }

  editDocument(response: any) {
    this.params.onEditClick(response);
  }

  onAPLoadRevert(): void {
    this.params.onAPLoadRevert(this.params?.node?.data);
  }

  onARLoadRevert(): void {
    this.params.onARLoadRevert(this.params?.node?.data);
  }

  onViewLoadClick() {
    this.params.onViewLoadClick(this.params?.node?.data);
  }

  onCapacityLoadClick() {
    this.params.onCapacityLoadClick(this.params?.node?.data);
  }

  editFleet(): void {
    this.params.onEditFleetClick(this.params);
  }
  saveFleet(): void {
    this.params.onSaveFleetClick(this.params?.node?.data);
  }
  cancelFleet(): void {
    this.params.onCancelFleetClick(this.params?.node?.data);
  }
}