import { RowModelType, SortDirection } from "@ag-grid-community/core";

export class Constants {
  //#region General constants
  static errorOccurred = 'An error occurred';
  static dateTimeFormat = 'MM/dd/yy HH:mm';
  static longDateTimeFormat = 'MM/dd/yy HH:mm:ss';
  static dateFormat = 'MM/dd/yy';
  static dateFilterFormat = 'yyyy-MM-dd';
  static sampleDocument = "FSCRates-V1.0.csv";
  static inactivateStatusConfirmation = 'Are you sure you want to inactivate this {0}?';
  static activateStatusConfirmation = 'Are you sure you want to activate this {0}?';
  static inactivateContactConfirmation = 'Are you sure you want to inactivate {0} as a contact?';
  static activateContactConfirmation = 'Are you sure you want to activate {0} as a contact?';
  static updateStatusDialogHeader = 'Update {0} status';
  static updateStatusDialogIcon = 'pi pi-info-circle';
  static addDialogHeader = 'Add {0}';
  static editDialogHeader = 'Edit {0}';
  static scoreCardDialogHeader = 'Score Card'; // TODO : change value based on sprint 3 work flow
  static toastTimeOut = 3000;
  static confirmation = 'Confirmation';
  static defaultTimezone = 'CT';
  static defaultTimezoneId = '2';
  static noRecordFound = 'No records available.';
  static suggestions = 'Suggestions';
  static logoutDialogHeader = 'Log out Confirmation';
  static logoutConfirmation = 'Are you sure you want to logout from the TMS?';
  static unknownUser = 'Unknown User';
  static confirmationPopupHeader = 'Confirmation';
  static defaultLocationConfirmation = 'Are you sure you want to set this location as default location?';
  static unknown = 'Unknown';
  static zeroNumber = 0;
  static oneNumber = 1;
  static saveDetailConfirmation = 'Do you want to save the details?';
  static cancelDetailConfirmation = 'Do you want to cancel the operation?';
  static cancelEditDetailConfirmation = 'Are you sure you don’t want to save the details?';
  static approveConfirmation = 'Are you sure you want to Approve the {0}?';
  static overlayNoRowsTemplate = '<span class="ag-overlay-no-rows-center">No records available.</span>';
  static inviteCarrier = 'Invite a Carrier';
  static markActive = 'Mark user Active/Inactive';
  static mcpStatus = 4;
  static tokenExpiredMessage = 'Token is expired';
  static editConfirmation = 'Are you sure, you want to edit this record?'
  static deleteConfirmation = 'Are you sure, you want to delete this record?'
  static selectDefaultText = 'Select {0} *';
  static assignUnassignModalTitle = 'Assign/Unassign {0}';
  static pleaseSelectDefaultText = 'Please Select';
  static defaultRateConfirmationStatus = "Pending";
  static creditLimitMaxValue = 99999999.99;
  static creditAmountLabel = 'Credit Amount';
  static rejectCarrier = 'Reject Carrier';
  static deleteConfirmationWithPlaceholder = 'Are you sure you want to delete this {0}?';
  static success = 'Success';
  static hoursValidationMax = 23;
  static minutesValidationMax = 59;
  static clearConfirmation = 'You have unsaved changes on the screen. Are you sure you want to clear the form?';
  static revertConfirmation = "Are you sure, you want to revert the check call?";
  static revertAllConfirmation = "Are you sure, you want to revert all the check calls?";
  static cancelConfirmation = 'Are you sure you don’t want to save the details?';
  static approved = 'Approved';
  static rejected = 'Rejected';
  static typeAheadSelectListSize = 15;
  static checkCallTypeAheadSelectListSize = 50;
  static unlockNotification = 'The screen is about to get unlocked in 30 seconds, please save your changes.';
  static lockRecord = "Load is locked.";
  static unlockRecordSuccess = "{0} unlocked successfully.";
  static loadStatus = 'Load Status';
  static backendDateFormat = 'yyyy-MM-dd';
  static invalidTabClass = 'badge rounded-pill rounded p-1 lh05 ms-3 text-bg-danger';
  static recoverLoadConfirmation = 'Are you sure you want to recover the load?';
  static noActivityFound = 'No activities found.';
  static contactValidationMsg = 'Please save pending details in contact section.';
  static contactEmailDuplicateValidation = 'This email already exists. Please try with different email.';
  static primaryContactExistsValidation = 'Primary contact already exists for this {0}.';
  static activateSuccessMessage = '{0} is activated successfully.';
  static inactivateSuccessMessage = '{0} is inactivated successfully.';
  static notApplicable = 'N/A';
  static approveRequestHeader = 'Approve {0}';
  static rejectRequestHeader = 'Reject {0}';
  static auditReviewSessionStorageKey = 'ag-grid-audit-review';
  static loadBoardSessionStorageKey = 'ag-grid-loadboard';
  static loadBoardCurrentView = 'ag-grid-current-view';
  static trackingBoardSessionStorageKey = 'ag-grid-tracking-board';
  static purchaseJournalSessionStorageKey = 'ag-grid-purchase-journal';
  static accountReceivableNewSessionStorageKey = 'ag-grid-account-receivable-new';
  static accountReceivableAgingSessionStorageKey = 'ag-grid-account-receivable-aging';
  static accountReceivableClosedSessionStorageKey = 'ag-grid-account-receivable-closed';
  static accountPayableNewSessionStorageKey = 'ag-grid-account-payable-new';
  static accountPayableAgingSessionStorageKey = 'ag-grid-account-payable-aging';
  static accountPayableClosedSessionStorageKey = 'ag-grid-account-payable-closed';
  static customerSessionStorageKey = 'ag-grid-customer-listing';
  static carrierSessionStorageKey = 'ag-grid-carrier-listing';
  static cancelLoad = 'CancelLoad';
  static cancelDialogHeader = 'Cancel {0}';
  static dnuHeader = 'Do Not Use {0}';
  static creditWarningMessage = 'The customer does not have enough credit to create the load. The Remaining credit limit is {0}. Please contact the accounting team.'
  static customerProfileTitle = 'Customer Profile';
  static waitTimeOutToUnlock = 30000;
  static documentType = 'documentType_{0}';
  static viewDocument = 'View Document';
  static documentUploadHeader = 'Document Upload';
  static pdfType = 'application/pdf';
  static tokenEmail = "rateconfirmation@transportationone.com";
  static invalidFileSizeMessage = 'File size cannot be greater than {0} MB.';
  static invalidFuelSurchargeFileSizeMessage = 'The selected file should be less than or equal {0} MB.';
  static maxFuelSurchargeFileSize = "15";
  static documentTypeAll = 'All';
  static invalidFileTypeMessage = 'Invalid File Type.';
  static advanceFilterDialogHeader = 'Advance Filter';
  static clearDetailsConfirmation = 'Are you sure you don’t want to save the details?';
  static userPermissionMessage = 'User does not have permission to view this profile.';
  static cancelResolveIncidentConfirmation = 'Are you sure you don’t want to resolve the incident?';
  static loadPostDialogHeader = 'Load Post';
  static unPostConfirmation = ' Are you sure you want to unpost the load from DAT?';
  static invalidDATConfirmation = 'You do not have DAT configured on TMS. Please contact your administrator.';
  static relaseDialogHeader = 'Release for Billing';
  static invalidHtmlMessage = "Html tags are not allowed."
  static viewManagement = "View Management"
  static setHighVisibilityConfirmation = 'Are you sure you want to mark this load as high visibility?';
  static removeHighVisibilityConfirmation = 'Are you sure you want to remove this load from high visibility?';
  static snoozedLoadMessage = 'Snoozed until :';
  static trackingBoardRefreshInterval = 120000;
  static loadCannotBePosted = 'Load cannot be posted.';
  static trackingBoardExceptionsKey = 'ag-grid-tracking-board-exceptions';
  static loadTrail = 'Load Trail';
  static userScoreCardDialogHeader = 'User Scorecard';
  static assignPopupHeader = 'Assign';
  static matchingCapacityConfirmation = 'Are you sure you want to match this truck to this load?';
  static offerConfirmation = 'Are you sure you want to assign the offer on the load?';
  static paradeUnpostConfirmation = 'Are you sure you want to unpost the load from Parade?';
  static loadEditFromAR = 'LoadEditAR';
  static loadEditFromAP = 'LoadEditAP';
  static loadEditFromAuditReview = 'LoadEditAuditReview';
  static loadEditFromAPCarrier = 'LoadEditAPCarrier';
  static viewPermissionMessage = 'View permission is required.';
  static primaryContactValidation = "Primary contact is required in contact section.";
  static loadProfileAccessorialSize = 1;
  static auditLogInfoMessage = 'Activity and Audit logs may take some time to get reflected in the below listing.';
  static accountReceivableId = 'AccountReceivableId';
  static accountPayableId = 'AccountPayableId';
  static releaseForBillingMessage = 'Load is released for billing successfully.';
  static notificationWarningMessage = 'Please update the static message details in the description box. Do not update the dynamic fields.';
  static contactRemoveConfirmationMessage = 'Are you sure you don’t want to save the details?';
  static carrierLoadBoardFiltersKey = 'carrier-load-board-filters';
  static carrierLoadBoardSelectedLoadDetailIdKey = 'carrier-load-board-selected-load-detail-id';
  static myCarrierSessionStorageKey = 'ag-grid-my-carrier';
  static myCustomerSessionStorageKey = 'ag-grid-my-customer';
  static timezonelength = 2;
  static carrierLoadBoardUserFiltersKey = 'carrier-load-board-user-filter';
  static primaryDepartmentLabel = 'Primary';
  static carrierRatesValidationForRecover = 'Line haul Carrier rates are required to cover load.';
  static previousUrlForLoadEdit = 'previousUrlForLoadEdit';
  static loadBoardAdvanceFilterSessionStorageKey = 'ag-grid-loadboard-advance-filters';
  static addUpdateRemoveBOL = 'AddUpdateRemoveBOL';
  static loadboardSessionStorageStatusFilterKey = 'loadboard-status-filter';
  static loadboardSessionStorageMyLoadFilterKey = 'loadboard-myLoad-filter';
  static loadboardSessionStorageRegionFilterKey = 'loadboard-region-filter';
  static manualOnboardCarrierConfirmationMessage = 'Are you sure you want to manually onboard the carrier?';
  static manualOffboardCarrierConfirmationMessage = 'Are you sure you want to manually offboard the carrier?';
  static deleteActivityConfirmation = "Are you sure, you want to delete the activity note?";
  static inactivateCustomerConfirmation = 'Are you sure you want to inactivate {0} as a customer?';
  static activateCustomerConfirmation = 'Are you sure you want to activate {0} as a customer?';
  static facilityContactsNotFound = 'Facility contacts not found.';
  static renewValidityPreferredLane = "Renew Lane";
  static myAwardSessionStorageKey = 'ag-grid-my-award';
  static awardSessionStorageKey = 'ag-grid-award-listing';
  static preferredLaneTitle = 'lane';
  static facilityAddressDuplicateMessage = 'This Facility Address already exists.';
  static awardUpdateStatusConfirmation = 'Are you sure you want to {0} the {1}?';
  static actionStatusPopupHeader = '{0} {1}';
  static contactCellPhoneDuplicateValidation = 'This cell phone already exists. Please try with different cell phone.';
  static contactCellPhoneEmailValidation = 'Either of the cell phone or email should be a mandatory filled by the user.';
  static lockProfileWarning = 'This profile is locked by {0}.';
  static salesLogInfoMessage = 'Sales Activity may take some time to get reflected in the below listing.';
  static myCapacitySessionStorageKey = 'ag-grid-my-capacity';
  static capacitySessionStorageKey = 'ag-grid-capacity-listing';
  static capacityStatusStorageFilterKey = 'capacity-status-filter';
  static awardSessionStorageStatusFilterKey = 'award-status-filter';
  static awardSelectedStatus = 'award-status-string';
  static notifyRepMessage = 'Are you sure you want to Notify Rep?';
  static facilityDetails = 'Facility Details';
  static loadNotInvoiceReadyPrimary = 'Please select release ready entries only. Kindly refresh the page and select the load entries again.';
  static loadNotInvoiceReadySecondary = 'Below mentioned entries are not release ready entries:';
  static bulkReleaseConfirmationMessage = 'Are you sure you want bulk release the selected entries?'
  static markAllAsReadMessage = 'Are you sure you want to mark all notifications as read?';
  static awardBid = 'Award Bid';
  static clearLoadCloneConfirmation = 'Are you sure you don’t want to clone the load?';
  static awardSingleSourceMessage = 'Cannot select Single Source, due to more than 1 approved capacity.';
  static typeAheadGlobalSearchSelectListSize = 20;
  static uploadBids = 'Upload Bids';
  static unsuccessful = '_Unsuccessful';
  static uploadDialogHeader = 'Upload {0}';
  static maxFileSize = 15;
  static invalidFileTypeMessageDetail = 'Allowed file types: {0}';
  static releaseReportCustomerSelection = "Either select contact or add additional recipient email id."
  static invalidAllInRateMessage = 'All In Rate should be equal or greater than FSC';
  static negativeAccessorialMessage = 'The added accessorial is not a negative accessorial.';
  static customerNegativeRateMessage = 'Accessorial amount should be greater than Total customer rate.';
  static carrierNegativeRateMessage = 'Accessorial amount should be greater than Total carrier rate.';
  static exportCSVMessage = 'Your file is being exported and will be ready shortly. Please wait while it is processed.';
  static trackingBoardCurrentView = 'ag-grid-tracking-board-current-view';
  static revertLoadConfirmation = 'Are you sure you want to revert the load?';
  static revertLoadPopupHeader = 'Revert load';
  static awardYieldSessionStorageKey = 'ag-grid-award-yield-listing';
  static myAwardYieldSessionStorageKey = 'ag-grid-my-award-yield';
  static includeExpiredAwardYieldSessionStorageKey = 'ag-grid-expired-award-yield';
  static requestStopConfirmationMessage = 'Are you sure you want to request this stop?';
  static confirmStopConfirmationMessage = 'Are you sure you want to confirm the stop?';
  static markLoadPriorityConfirmation = 'Are you sure you want to mark the load as priority load?';
  static removeLoadPriorityConfirmation = 'Are you sure you want to unmark the load as priority load?';
  static markLoadPriorityHeader = 'Mark load priority';
  static removeLoadPriorityHeader = 'Unmark load priority';
  static carrierLoadPreferedLane = 'LoadMatch';
  static loadboardSessionStoragePriorityFilterKey = 'loadboard-priority-filter';
  //#endregion

  //#region Validation Messages
  static requiredValidation = '{0} is required.';
  static minLengthValidation = '{0} must contain at least {1} character(s)';
  static maxLengthValidation = 'Character Limit Exceeded';
  static minValueValidation = '{0} should be greater than or equal to {1}';
  static maxValueValidation = '{0} should be less than or equal to {1}';
  static duplicateValidation = 'This {0} already exists.';
  static emailValidation = 'Please enter a valid {0}.';
  static invalidTimeValidation = 'Invalid Time.';
  static websiteValidation = "Please enter a valid website";
  static maxBuyValidation = 'Max buy should always be less than total of (Linehaul + Fuel Surcharge).';
  static targetRateValidation = 'Target rate should always be less than or equal to max buy.';
  static alphaNumericValidation = "Only Alpha Numeric values allowed for {0}";
  static invalidMCNumber = "Invalid MC/DOT number.";
  static amountValidation = '{0} should be less than or equal to ${1}';
  static unlockConfirmation = 'Are you sure you want to unlock this {0}?';
  static dnuCarrierValidation = 'The carrier is marked as DNU.';
  static approvedBidAmountValidation = 'The approved amount should be less than the bid amount or equal to the bid amount.';
  static toShipDateValidation = 'To Ship date should be greater than the From Ship date.';
  static toDeliveryDateValidation = 'To Delivery date should be greater than the From Delivery date.';
  static invalidDateValidation = '{0} should not be less than current date.';
  static valueNotMatch = '{0} does not match.';
  static invalidAppointmentDate = 'Appt Latest should be greater than the Appt Earliest date.';
  static invalidAppointmentTime = 'Appt Latest should be greater than the Appt Earliest time.';
  static invalidDropDate = 'Latest date should be greater than the Earliest date.';
  static invalidDropTime = 'Latest time should be greater than the Earliest time.';
  static stopReorderValidation = 'Stop {0} is completed. You cannot reorder it.';
  static invalidDueDate = 'Due date should be greater than Invoice date.';
  static invalidBillDueDate = 'Due date should be greater than Bill date.';
  static invalidAmountValidation = 'Total {0} amount should be greater than 0.';
  static renewSuccessMessage = 'Complete';
  static startDateValidation = 'Start date should be less than the End date.';
  static endDateValidation = 'End date should be greater than the Start date.';
  static startTimeValidation = 'Start time should be less than the End time.';
  static endTimeValidation = 'End time should be greater than the Start time.';
  static facilityDateValidation = 'Facility is not operating on this day.';
  static facilityTimeValidation = 'Facility is not operating during this time.';
  static duplicateAccessorialMapping = "This accessorial mapping already exists.";
  static invalidTextValidation = 'Invalid input detected. Please enter only plain text.';
  static duplicateCapacityValidation = 'Capacity already requested.';
  static carrierRateVsTargetRate = 'Carrier rate should be lower than or equal to Target rate.';
  static totalCarrierRateVsTargetRate = 'Total Carrier rate should be lower than or equal to Target rate.';
  static carrierRateVsMaxBuy = 'Carrier rate should be equal to or lower than Max Buy.';
  //#endregion

  //#region Toast Messages
  static badRequest = 'The server is unable to process your request due to invalid syntax. Please contact to your administrator';
  static unauthorized = 'The server could not verify that you are authorized to access while processing your request';
  static notFound = 'The Web server cannot find API endpoint you asked for. Please check the endpoint URL ensure that the path is correct';
  static internalServerError = 'The server encountered an internal error and was unable to complete your request. Please contact to your administrator.';
  static apiNotWorking = 'An error occurred while processing your request. Please contact to your administrator.';
  static inValidMessage = 'Invalid {0}.';
  static updateSuccess = '{0} updated successfully.';
  static uploadSuccess = 'Document uploaded successfully.';
  static documentDeleteSuccess = 'Document deleted successfully.';
  static loadNotExist = 'Load number does not exist.';
  static carrierAssignmentError = "You cannot assign the carrier. Please contact the administration."
  static userDisableMessage = 'Your account has been deactivated, Please contact administration.';
  static addFacilityValidationMessage = 'Please add Facility Details.';
  static beingUploadMessage = 'Document is being processed.';
  static resendCheckCallSuccessMessage = 'Check call resent successfully.';
  //#endregion

  //#region AG Grid global properties
  static pageSize = 20;
  static masterGridPageSize = 40;
  static cacheBlockSize = 5;
  static sortingOrder: SortDirection[] = ['asc', 'desc'];
  static agGridListStyle = "width: auto; height: 786px";
  static agGridMastersStyle = "width: auto; height: 728px";
  static infiniteRowModelType: RowModelType = 'infinite';
  static serverSideRowModelType: RowModelType = 'serverSide';
  static agGridAccountingListStyle = "width: auto; height: 755px";
  static awardApprovedCapacityGridStyle = "width: auto; height:180px";
  static accountingPageSize = 75;
  static loadMatchGridStyle = "width: auto; height: 700px";
  //#endregion

  //#region frontend path url
  static createCustomerUrl = '/customer/add';
  //#endregion

  //#region common properties
  static customerId = 'id';
  static carrierId = 'carrierId';
  static loadDetailId = 'loadDetailId';
  static sourceType = 'SourceType';
  //#endregion

  //#region Zipcode properties
  static zipCodeSelectListSize = 15;
  //#endregion

  //#carrier validation
  static dnuStatus = 'Do Not Use';
  static mcDotNumberValidation = 'Either MC Number or DOT Number is required';
  static cellPhoneOfficePhoneValidation = 'Either cell phone or office phone is required';
  static mcNumberTextShotText = "MC";
  static validationTitle = "Validation";
  static loadCarrierNotActive = "The carrier is not Active.";
  static loadCarrierAlreadyExist = 'This carrier is already added on the load.';
  static loadCarrierDNU = "The carrier is marked with DNU.";
  static loadCarrierNonCompliant = "The carrier is non-compliant.";
  static loadCarrierNotInsured = "The insurance is not valid for this carrier.";
  static carrierContactValidation = "Please save pending details in contact section.";
  static defaultCarrierTireValue = 4;
  static loadCarrierApprovedAmount = "Carrier rate should be lower than approved bid amount.";
  static duplicateCarrierCapacityConfirmation = "The capacity is available on this location for on this date. Do you want to add this?";
  static invalidCarrier = "Invalid Carrier Details.";
  static carrierNotOnboardedError = "This carrier is not onboarded on TMS. You cannot assign this carrier.";
  //#end carrier validation

  //#load stop validation
  static stopValiation = 'First stop should be pickup and last stop should be drop.';
  static AppointmentFirstComeFirstServeValiation = 'Please select either APPT or FCFS on the Stop section.';
  static LiveDropTrailerValiation = 'Please select either L or DT on the Stop section.';
  static atLeastTwoStopsRequired = 'At least two stops are required.';
  static waitTimeOutUnlockLoad = 30000;
  static defaultMileage = 1;
  //#end load stop validation

  //# Factoring Comapny
  static factoringComapnyInactivationMessage = "The factoring company cannot be inactivated as it is associated with {0} carriers."
  //#end Factoring Comapny

  //# EDI Load Tenders
  static ediLoadTendersConfirmationMessage = "Are you sure you want to accept the load tender?";
  static ediLoadTendersConfirmationHeaderMessage = "Accept EDI Request";
  static ediLoadTendersRejectDialogHeader = "Reject EDI Request";
  static ediViewPayload = "View Load Tender Details";
  static ediAssignLoadTender = 'Assign EDI Load Tender';
  static ediLoadTenderRejectMessage = 'Rejected EDI Load Tender Request';
  static ediLoadTenderUpdates = 'Load Tender Updates';
  static ediSenderIdNotMatch = 'Sender ID does not match.';
  static ediAssignSuccessMessage = 'Load assigned successfully';
  static bulkAcceptMessage = 'Are you sure you want bulk accept the selected entries?';
  //#endregion

  //#region Purchase Journal
  static purchaseJournalCreationFailed = 'Cannot create a purchase journal for this Load#';
  static purchaseJournalVoidConfirmationMessage = 'Are you sure, you want to void the added purchase journal from Load?';
  //#endregion


  //#region Audit Review
  static auditReviewReleaseConfirmationMessage = 'Are you sure you want to release the entry back to the billing?';
  //#endregion

  //#region  AR
  static generateInvoice = 'Generate Invoice';
  static generateInvoiceConfirmation = 'Are you sure you want to create an invoice?';
  static generateMultipleInvoiceConfirmation = 'Are you sure you want to create invoice for selected entries?';
  static selectInvoiceReadyEntries = 'Please select invoice ready entries only';
  static invoiceVoidWarningMessage = 'The invoice cannot be deleted, since payment is not completed';
  static moveInvoiceConfirmationMessage = 'Do you want to move this account receivable entry to PRE-AR or AR-CLOSED?';
  static voidInvoiceConfirmationMessage = 'Are you sure you want to void the added invoice from {0}?';
  static netSuiteInvoiceCNotfound = 'NetSuite Invoice not found.';
  static netSuiteInvoiceError = 'The invoice creation is under process. Please try after sometime.';
  static netSuiteBillNotfound = 'NetSuite Bill not found.';
  static resendInvoiceToCustomer = 'Are you sure you want to resend the invoice# {0} to Customer?';
  static resendInvoiceToEDI = 'Are you sure you want to resend the invoice# {0} to EDI?';
  //#endregion

  //#region  AP
  static billVoidWarningMessage = 'The bill cannot be deleted, since payment is not completed';
  static moveBillConfirmationMessage = 'Do you want to move this account payable entry to PRE-AP or AP-CLOSED?';
  static voidBillConfirmationMessage = 'Are you sure you want to remove the added bill from {0}?';
  //#endregion
  //#region  InAppNotification
  static inappNotificationKey = 'confirm';
  static inappNotificationSeverity = 'warn';
  static inappNotificationSummary = 'Are you sure?';
  static inappNotificationDetail = 'Confirm to proceed';
  static inappNotificationCloseIcon = 'pi pi-times-circle';
  static inappNotificationLife = 8000;
  //#endregion

  static isFormDirtyMessage = 'Changes you made will be lost. Do you want to leave?';
  static maxUnlockTime = 'This load is already going to be unlocked in {0} seconds.';

  //#region facility score card
  static showSuccessMessage = 'showSuccessMessage';
  static cloneSuccessMessage = 'Load cloned successfully.';
  static inActiveCustomerMessage = 'The status of the customer is not active.';
  //#endregion
  static documentProcessMessage = 'Document is being processed.';
  static refreshTokenURL = 'sso/refresh';
  static eiaRateCode = 'EIARate';
  static eiaNotExist = 'EIA Diesel fuel value doesn’t exist in the table';
  static releaseReportNotificationCode = "AccountRevceivableAgingReport";
  static invalidAdditionalRecipents = "Please enter a valid email."

  //Export To CSV
  static maxPageSizeForExportListing = 10000;

  //Quick View
  static assignCarrierDataFromQuickView = 'assignCarrierDataFromQuickView';

  static createSuccess = '{0} created successfully.'

  //#region LoadDocument
  static cancelEditDocumentConfirmation = 'Are you sure you don’t want to update the document type?';
  //#endregion

  //MacroPoint
  static notOnboardedMessage = 'Not-Onboarded';

  // Equipments for validations
  static flatBadEquipments = ['53F - Flatbed', '48F - Flatbed'];
  static reeferEquipments = ['53R - Reefer', '53V - Dry Van'];

  static waterfallOverlayNoRowsTemplate = '<span class="ag-overlay-no-rows-center">No waterfall carriers currently assigned.</span>';

  //Load Waterfall
  static acceptLoadWaterfall = 'Accept Waterfall';
  static rejectLoadWaterfall = 'Reject Waterfall';

  static coverLoad = 'Cover the Load';
  static loadWaterfallPriorWarning = 'You have 30 seconds left to act on the waterfall load {0}';
}
