import { ColDef, IDateFilterParams } from '@ag-grid-community/core';
import { AccountReceivableModel } from '@app/modules/accounting/account-receivable/account-receivable-model';
import { CreditRequestStatusModel } from '@app/modules/accounting/credit-request/credit-request.model';
import { Constants } from '../constants/constants';
import { AccountInvoiceStatus, AuditLogActivityType, CarrierStatus, EdiLoadTenderListStatus, FacilityStatus, FilterFieldType, FilterOperationType, IncidentStatus, LoadCarrierBidStatus, LoadStatus, LoadTruckMatchStatus, PurchaseJournalStatus, RateConfirmationStatus, AwardStatusList, CapacityStatus, SubmittedCapacityStatus, FacilityCustomerStatus, AwardSubmittedCapacityStatus } from '../constants/enums';

export class AgGridHelpers {

    // This method is for date filters used in ag-grid
    public static GetDateFilterParams(): IDateFilterParams {
        return {
            comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
                const dateAsString = new Date(cellValue);

                if (dateAsString == null) return -1;

                if (filterLocalDateAtMidnight.getDate() === dateAsString.getDate() && filterLocalDateAtMidnight.getMonth() === dateAsString.getMonth() &&
                    filterLocalDateAtMidnight.getFullYear() === dateAsString.getFullYear()) {
                    return 0;
                }
                if (dateAsString < filterLocalDateAtMidnight) {
                    return -1;
                }
                if (dateAsString > filterLocalDateAtMidnight) {
                    return 1;
                }
                return 0;
            },
            minValidYear: 2000,
            filterPlaceholder: 'Filter date',
            inRangeFloatingFilterDateFormat: 'MM Do YYYY',
            maxNumConditions: 1,
            buttons: ['apply', 'reset'],
            filterOptions: ['equals', 'greaterThan', 'lessThan', 'inRange'],
            inRangeInclusive: true
        };
    }
    public static StatusIconCellRenderer(isActive: boolean) {
        const text = isActive ? 'Active' : 'Inactive';
        const iconColor = isActive ? 'success' : 'danger';
        const html = `<span class="badge badge-status text-bg-${iconColor}">${text}</span>`;
        return html;
    }

    public static CreditRequestStatusIconCellRenderer(status?: CreditRequestStatusModel) {
        const iconColor = status?.name == Constants.approved ? 'open' : status?.name == Constants.rejected ? 'rejected' : 'loaded';
        const html = status?.name && `<span class="badge badge-status text-bg-${iconColor}">${status.name}</span>`;
        return html;
    }

    public static ComplianceInsuranceTitleCellRenderer(title: string) {
        const html = `<strong>${title}</strong>`;
        return html;
    }

    public static StatusCellRenderer(status?: string) {
        const iconColor = status?.replace(/[\s/-]/g, "").toLowerCase() || 'success';
        const html = status && `<span class="badge badge-status text-bg-${iconColor}">${status}</span>`;
        return html;
    }
    public static PreferredLaneStatusCellRenderer(status?: string) {
        const iconColor = status == 'Active' ? 'success' : 'danger';
        const html = status && `<span class="badge badge-status text-bg-${iconColor}">${status}</span>`;
        return html;
    }

    public static CustomerAccessorialStatusCellRenderer(status?: string) {
        const iconColor = status === 'Active' ? 'success' : 'danger';
        const html = status && `<span class="badge badge-status text-bg-${iconColor}">${status}</span>`;
        return html;
    }

    public static AuditLogTypeCellRenderer(status?: string) {
        let iconColor = '';
        switch (status) {
            case AuditLogActivityType.Add:
                iconColor = 'primary'
                break;
            case AuditLogActivityType.Delete:
                iconColor = 'danger'
                break;
            case AuditLogActivityType.Edit:
                iconColor = 'primary'
                break;
            case AuditLogActivityType.Update:
                iconColor = 'success'
                break;
            default:
                iconColor = 'primary'
                break;
        }
        const html = `<span class="badge badge-status text-bg-${iconColor}">${status || ''}</span>`;
        return html;
    }

    public static NameLinkCellRenderer(name?: string) {
        const html = `<a class="text-primary fw-semibold cursor-pointer">${name || ''}</a>`;
        return html;
    }

    public static CarrierNameCellRenderer(name?: string, dailySalesTouchPointAdded?: boolean) {
        let html = `<span style="opacity: ${dailySalesTouchPointAdded ? '1' : '0'}; pointer-events: ${dailySalesTouchPointAdded ? 'auto' : 'none'};">
        <span class="badge rounded-pill rounded p-1 lh05 mr-1 bg-success">&nbsp;</span></span>`;
        html += `<a class="text-primary fw-semibold cursor-pointer">${name || ''}</a>`;
        return html;
    }
    public static RateConfirmationStatusIconCellRenderer(status: string) {
        const iconColor = status == RateConfirmationStatus.Signed ? 'success' : status == RateConfirmationStatus.Expired ? 'rejected' : 'loaded';
        const html = `<span class="badge badge-status text-bg-${iconColor}">${status}</span>`;
        return html;
    }

    public static phoneNumberCellRenderer(params: any): string {
        return params.value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3');
    }

    public static getColumnDefinition(): ColDef {
        return {
            sortable: true,
            resizable: true,
            filter: true,
            lockPinned: true,
            filterParams: {
                maxNumConditions: 1
            }
        }
    }

    public static getTextFilterParams() {
        return {
            buttons: ['apply', 'reset'],
            filterOptions: ['contains', 'notContains', 'equals'],
            suppressAndOrCondition: true,
            trimInput: true,
        }
    }

    public static getNumberFilterParams() {
        return {
            buttons: ['apply', 'reset'],
            filterOptions: ['equals', 'greaterThan', 'lessThan', 'inRange'],
            suppressAndOrCondition: true
        }
    }

    public static CellWithInfoIconRenderer(cellValue: string, toolTipText: string) {
        const html = `<div style="display: flex; align-items: center">
                <div class="ellipsis">${cellValue || ''}</div>
                <span class="material-symbols-outlined text-info ms-2 fw-semibold medium cursor-pointer" title="${toolTipText || ''}">info</span></div>`;
        return cellValue ? html : "";
    }

    public static CityStateCellRenderer(city: string, state: string) {
        const html = `<div style="line-height:16px;padding-top: 3px;">
            <div class="text-primary ellipsis text-uppercase">${city || ''}</div>
            <div class="ellipsis text-uppercase">${state || ''}</div>
        </div>`;
        return html;
    }

    public static TrackingBoardCityStateCellRenderer(city: string, state: string) {
        const html = `<div style="line-height:16px;padding-top: 3px;font-weight: 600">
            <div class="text-primary ellipsis text-uppercase">${city || ''}</div>
            <div class="ellipsis text-uppercase">${state || ''}</div>
        </div>`;
        return html;
    }

    public static DriverCellRenderer(name: string, phone: string) {
        const html = `<div style="line-height:16px;padding-top: 3px;">
            <div class="text-primary ellipsis text-uppercase">${name || ''}</div>
            <div class="ellipsis text-uppercase">${phone || ''}</div>
        </div>`;
        return html;
    }

    public static PurchaseJournalStatusIconCellRenderer(status: string) {
        let iconColor = '';
        switch (status) {
            case PurchaseJournalStatus.Paid:
                iconColor = 'open'
                break;
            case PurchaseJournalStatus.Inactive:
                iconColor = 'danger'
                break;
            case PurchaseJournalStatus.Active:
                iconColor = 'loaded'
                break;
            case PurchaseJournalStatus.PartiallyPaid:
                iconColor = 'dispatch'
                break;
            default:
                iconColor = 'danger'
                break;
        }
        const html = `<span class="badge badge-status text-bg-${iconColor}">${status || ''}</span>`;
        return html;
    }

    public static AccountInvoiceStatusCellRenderer(status: string) {
        let iconColor = '';
        switch (status) {
            case AccountInvoiceStatus.NotPaid:
                iconColor = 'danger';
                break;
            case AccountInvoiceStatus.PartialPaid:
                iconColor = 'load2';
                break;
            default:
                iconColor = '';
                break;
        }
        const html = `<span class="badge badge-status text-bg-${iconColor}">${status || ''}</span>`;
        return html;
    }

    public static PickupDateCellRenderer(earliestDate: string, latestDate: string, isPickupConfirmed?: any) {
        const html = `<div style="line-height:16px;padding-top: 3px;" class="d-flex align-items-center">
            ${isPickupConfirmed === "true" ? `<div><i class="p-element material-symbols-outlined text-danger-dark span-gap mr-2">chronic</i></div>` : ``}
            <div>
            <div class="text-primary">${earliestDate || ''}</div>
            <div>${latestDate || ''}</div>
            </div>
        </div>`;
        return html;
    }

    public static TruckMatchResultStatusIconCellRenderer(carrierStatusName: string, status: string) {
        const statusColor: { [key: string]: string } = {
            [CarrierStatus.New]: "loaded",
            [CarrierStatus.Invited]: "loaded",
            [CarrierStatus.InReview]: "loaded",
            [CarrierStatus.Active]: "success",
            [CarrierStatus.DoNotUse]: "danger",
            [CarrierStatus.NonCompliant]: "loaded"
        };
        const iconColor = statusColor[(carrierStatusName ?? '').toLocaleLowerCase()] || "gray";
        const html = `<span class="badge badge-status-truck-match text-white bg-${iconColor}">${status}</span>`;
        return html;
    }

    public static LoadStatusIconCellRenderer(loadStatus?: string) {
        const iconColor = AgGridHelpers.GetLoadIconClass(loadStatus || 'draft');
        const html = loadStatus && `<span class="badge badge-status ${iconColor}">${loadStatus}</span>`;
        return html;
    }

    public static TrackingBoardStatusIconCellRenderer(loadStatus?: string) {
        const iconColor = AgGridHelpers.GetLoadIconClass(loadStatus || 'draft');
        const html = loadStatus && `<span class="badge badge-status badge-status-tracking-board ${iconColor}">${loadStatus}</span>`;
        return html;
    }

    public static GetLoadIconClass(loadStatus: string): string {
        if (loadStatus === LoadStatus.Draft ||
            loadStatus === LoadStatus.Open ||
            loadStatus === LoadStatus.Waterfall ||
            loadStatus === LoadStatus.Assigned) {
            return 'text-bg-load1';
        } else if (
            loadStatus === LoadStatus.Covered ||
            loadStatus === LoadStatus.Dispatched ||
            loadStatus === LoadStatus.AtPickup ||
            loadStatus === LoadStatus.Loaded ||
            loadStatus === LoadStatus.InTransit ||
            loadStatus === LoadStatus.AtDestination ||
            loadStatus === LoadStatus.Delivered
        ) {
            return 'text-bg-load2';
        } else {
            return 'text-bg-load3';
        }
    }

    public static EDIRefrenceStatusIconCellRenderer(ediStatus?: string) {
        const iconColor = AgGridHelpers.GetEDIRefrenceIconClass(ediStatus || 'Pending');
        const html = ediStatus && `<span class="badge badge-status ${iconColor}">${ediStatus}</span>`;
        return html;
    }

    public static LoadNumberWithStatusRenderer(load?: AccountReceivableModel) {
        const html = load?.isInvoiceReady ? `<span class="badge badge-status badge-status-check text-bg-active border-0" style="border:0!important"><a class="text-primary fw-semibold cursor-pointer">${load?.loadDetailId || ''}</a></span>` :
            `<span class="badge-status ml-3 pl-1"><a class="text-primary fw-semibold cursor-pointer">${load?.loadDetailId || ''}</a></span>`;
        return html;
    }

    public static GetEDIRefrenceIconClass(ediStatus: string) {
        switch (ediStatus) {
            case EdiLoadTenderListStatus.Pending:
                return 'text-bg-loaded';
            case EdiLoadTenderListStatus.Accepted:
                return 'text-bg-success';
            case EdiLoadTenderListStatus.Rejected:
                return 'text-bg-rejected';
            case EdiLoadTenderListStatus.Cancelled:
                return 'text-bg-draft';
            case EdiLoadTenderListStatus.Assigned:
                return 'text-bg-onhold';
            default:
                return 'text-bg-draft';
        }
    }

    public static LoadCheckCallStatusIconCellRenderer(isLoadStatusUpdated: boolean, loadCheckCallStatus?: string) {
        const iconColor = loadCheckCallStatus?.replace(/\s/g, "").toLowerCase() || 'open';
        if (isLoadStatusUpdated)
            return loadCheckCallStatus && `<span class="badge badge-status badge-status-text text-bg-${iconColor}" style="background: #F4F7F9 !important;border-color: #11797D !important;color: #11797D !important;">${loadCheckCallStatus}</span>`;
        else
            return loadCheckCallStatus && `<span class="badge badge-status badge-status-text text-bg-${iconColor}">${loadCheckCallStatus}</span>`;
    }

    public static LoadNumberCellRenderer(loadDetailId: string) {
        const html = `<a class="text-primary fw-semibold">${loadDetailId || ''}</a>`;
        return html;
    }

    public static LoadBoardNumberCellRenderer(loadDetailId: string, openIncidents: number, offerCount: number, rateConfirmationStatus: string, isRateConfirmationSigned: boolean) {
        const redColor = 'text-danger';
        const orangeColor = 'text-reserved';
        const greenColor = 'text-success';
        let color = "";
        if (rateConfirmationStatus === RateConfirmationStatus.Pending) {
            color = orangeColor;
        }
        else if (rateConfirmationStatus === RateConfirmationStatus.Expired) {
            color = redColor;
        }
        else if (rateConfirmationStatus === RateConfirmationStatus.Resent || isRateConfirmationSigned == true) {
            color = greenColor;
        }
        else {
            color = '';
        }

        let html = `
        <span  style="opacity: ${openIncidents ? '1' : '0'}; pointer-events: ${openIncidents ? 'auto' : 'none'};">
            <i class="p-element material-symbols-outlined text-danger-dark span-gap mr-2">report</i>
            </span><span style="opacity: ${(rateConfirmationStatus != "" && rateConfirmationStatus != RateConfirmationStatus.Forwarded) ? '1' : '0'}; pointer-events: ${(rateConfirmationStatus != "" && rateConfirmationStatus != RateConfirmationStatus.Forwarded) ? 'auto' : 'none'}}; pointer-events:">
           <i class="p-element material-symbols-outlined ${(rateConfirmationStatus != "") ? color : ''} span-gap mr-2">export_notes</i>
           </span>
         <span><a class="text-primary fw-semibold">${loadDetailId || ''}</a></span>`;
        if (offerCount > 0) {
            html += `<span class="badge bg-primary ms-2">${offerCount}</span>`;
        }
        return html;
    }

    public static LoadIdCellRenderer(id?: number) {
        const html = `<a class="text-primary fw-semibold">${id || ''}</a>`;
        return html;
    }

    public static LoadNumberTruckMatchCellRenderer(loadNumber?: string) {
        const html = `<a class="text-primary fw-semibold">${loadNumber || ''}</a>`;
        return html;
    }

    public static GetFilterOperationType(operationType: string): FilterOperationType {
        switch (operationType.toLowerCase()) {
            case FilterOperationType.Contains.toLowerCase():
                return FilterOperationType.Contains;
            case FilterOperationType.NotContains.toLowerCase():
                return FilterOperationType.NotContains;
            case FilterOperationType.Equals.toLowerCase():
                return FilterOperationType.Equals;
            case FilterOperationType.GreaterThan.toLowerCase():
                return FilterOperationType.GreaterThan;
            case FilterOperationType.LessThan.toLowerCase():
                return FilterOperationType.LessThan;
            case FilterOperationType.In.toLowerCase():
                return FilterOperationType.In;
            case FilterOperationType.InRange.toLowerCase():
                return FilterOperationType.InRange;
            default:
                return FilterOperationType.Contains;
        }
    }

    public static GetFilterOperationTypeWithDate(operationType: string): FilterOperationType {
        switch (operationType.toLowerCase()) {
            case FilterOperationType.Equals.toLowerCase():
                return FilterOperationType.Equals;
            case FilterOperationType.GreaterThan.toLowerCase():
                return FilterOperationType.LessThan;
            case FilterOperationType.LessThan.toLowerCase():
                return FilterOperationType.GreaterThan;
            case FilterOperationType.In.toLowerCase():
                return FilterOperationType.In;
            case FilterOperationType.InRange.toLowerCase():
                return FilterOperationType.InRange;
            default:
                return FilterOperationType.Contains;
        }
    }

    public static GetFilterFieldType(fieldType: string): FilterFieldType {
        switch (fieldType.toLowerCase()) {
            case FilterFieldType.Number.toLowerCase():
                return FilterFieldType.Number;
            case FilterFieldType.Set.toLowerCase():
                return FilterFieldType.Set;
            case FilterFieldType.Date.toLowerCase():
                return FilterFieldType.Date;
            case FilterFieldType.Status.toLowerCase():
                return FilterFieldType.Status;
            default:
                return FilterFieldType.Text;
        }
    }

    public static dollarFormatter(params: any): string {
        if (params.value) {
            const formattedAmount = Number(params.value).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
            return formattedAmount;
        }
        else {
            return '';
        }
    }

    public static getFloatingFilterPlaceholder() {
        const inputElements = document.querySelectorAll('.ag-input-field-input.ag-text-field-input, .ag-input-field-input.ag-number-field-input');

        inputElements.forEach((obj) => {
            const ariaLabel = obj.getAttribute('aria-label') || '';
            const placeholder = ariaLabel.replace('Filter Input', '');
            obj.setAttribute('placeholder', placeholder);
        });
    }

    public static CarrierBidStatusIconCellRenderer(status: string) {
        let iconColor = 'primary';
        switch (status) {
            case LoadCarrierBidStatus.Approved:
                iconColor = 'success';
                break;
            case LoadCarrierBidStatus.Rejected:
                iconColor = 'danger';
                break;
            case LoadCarrierBidStatus.Dead:
                iconColor = 'gray';
                break;
            case LoadCarrierBidStatus.Open:
                iconColor = 'warning';
                break;
        }

        const html = `<span class="badge badge-status text-bg-${iconColor}">${status}</span>`;
        return html;

    }
    public static TruckMatchStatusIconCellRenderer(status: string, carrierStatus: string) {
        let iconColor = '';
        switch (carrierStatus) {
            case LoadTruckMatchStatus.Active:
                iconColor = 'success';
                break;
            case LoadTruckMatchStatus.DoNotUse:
                iconColor = 'danger';
                break;
            case LoadTruckMatchStatus.New:
            case LoadTruckMatchStatus.Invited:
            case LoadTruckMatchStatus.InReview:
            case LoadTruckMatchStatus.NonCompliant:
                iconColor = 'loaded';
                break;
            default:
                iconColor = 'text-draft';
        }

        const html = `<span class="badge badge-status-truck-match text-white bg-${iconColor}">${status}</span>`;
        return html;

    }

    public static IncidentStatusIconCellRenderer(status: string) {
        let iconColor = 'loaded';
        switch (status) {
            case IncidentStatus.Open:
                iconColor = 'loaded';
                break;
            case IncidentStatus.Resolved:
                iconColor = 'success';
                break;
        }
        const html = `<span class="badge badge-status text-bg-${iconColor}">${status}</span>`;
        return html;

    }
    public static IncidentLoadStatusIconCellRenderer(status: string) {
        const html = `<span class="badge badge-status no-icon">${status}</span>`;
        return html;

    }

    public static currencyFormat(value: number) {
        if (isNaN(value) || value == null) {
            value = 0;
        }

        const roundedValue = Math.round(value * 100) / 100;
        const formattedValue = roundedValue % 1 === 0 ? roundedValue.toFixed(0) : roundedValue.toFixed(2);
        const parts = formattedValue.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return `$${parts.join('.')}`;
    }
    public static NewGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public static FacilityStatusCellRenderer(status: string) {
        let iconColor = '';
        switch (status) {
            case FacilityStatus.Pending:
                iconColor = 'loaded';
                break;
            case FacilityStatus.Active:
                iconColor = 'success';
                break;
            case FacilityStatus.Inactive:
                iconColor = 'danger';
                break;
            default:
                iconColor = '';
                break;
        }
        const html = `<span class="badge badge-status text-bg-${iconColor}">${status || ''}</span>`;
        return html;
    }

    public static CapacitytatusCellRenderer(status: string) {
        let iconColor = '';
        switch (status) {
            case CapacityStatus.Awarded:
                iconColor = 'open';
                break;
            case CapacityStatus.Active:
                iconColor = 'cover';
                break;
            case CapacityStatus.Inactive:
                iconColor = 'danger';
                break;
            default:
                iconColor = '';
                break;
        }
        const html = `<span class="badge badge-status text-bg-${iconColor} text-capitalize">${status.toLowerCase() || ''}</span>`;
        return html;
    }

    public static AwardStatusIconCellRenderer(awardStatus?: string) {
        const iconColor = AgGridHelpers.GetAwardIconClass(awardStatus || 'Inactive');
        const html = awardStatus && `<span class="badge badge-status text-bg-${iconColor}">${awardStatus}</span>`;
        return html;
    }

    public static GetAwardIconClass(awardStatus: string) {
        switch (awardStatus) {
            case AwardStatusList.Bids:
                return 'cover';
            case AwardStatusList.Active:
                return 'open';
            case AwardStatusList.Expired:
                return 'reserved';
            case AwardStatusList.Inactive:
                return 'danger';
            case AwardStatusList.Lost:
                return 'released';
            case AwardStatusList.Pending:
                return 'loaded';
            case AwardStatusList.Validated:
                return 'onhold';
            case AwardStatusList.Rejected:
                return 'warning';
            default:
                return 'warning';
        }
    }

    public static OriginCityStateCellRenderer(city: string, state: string, originDropTrailer: any) {
        const html = originDropTrailer ? `<span class="material-symbols-outlined text-primary">rv_hookup</span> ${city || ''}, ${state || ''}` : `${city || ''}, ${state || ''}`;
        return html;
    }

    public static DestinationCityStateCellRenderer(city: string, state: string, destinationDropTrailer: any) {
        const html = destinationDropTrailer ? `<span class="material-symbols-outlined text-primary">rv_hookup</span> ${city || ''}, ${state || ''}` : `${city || ''}, ${state || ''}`;
        return html;
    }

    public static RatesWithFSCRenderer(rates: any, includeFSC: any) {
        const html = includeFSC ? `<span class="align-items-center d-flex gap-1"><span class="material-symbols-outlined text-primary">local_gas_station </span>${rates || ''}</span>` :
            `<span class="align-items-center d-flex gap-1"><span class="material-symbols-outlined text-primary invisible">local_gas_station </span>${rates || ''}</span>`;
        return html;
    }

    public static SubmittedCapacityStatusCellRenderer(status: string) {
        let iconColor = '';
        switch (status) {
            case SubmittedCapacityStatus.Pending:
                iconColor = 'loaded';
                break;
            case SubmittedCapacityStatus.Awarded:
                iconColor = 'success';
                break;
            case SubmittedCapacityStatus.Rejected:
                iconColor = 'danger';
                break;
            default:
                iconColor = '';
                break;
        }
        const html = `<span class="badge badge-status text-bg-${iconColor}">${status || ''}</span>`;
        return html;
    }

    public static FacilityCustomerStatusCellRenderer(isActive?: boolean) {
        const iconColor = isActive == true ? 'success' : 'danger';
        const html = `<span class="badge badge-status text-bg-${iconColor}">${isActive == true ? FacilityCustomerStatus.Active.toString() : FacilityCustomerStatus.Inactive.toString()}</span>`;
        return html;
    }

    public static LoadHistoryStatusCellRenderer(status: string) {
        const html = `<span class="badge badge-status text-bg-loaded">${status || ''}</span>`;
        return html;
    }

    public static AwardWaterfallSumbittedCapacity(awardStatus?: string) {
        const iconColor = AgGridHelpers.GetAwardApprovedCapacityIconClass(awardStatus || 'Pending');
        const html = awardStatus && `<span class="badge badge-status ${iconColor}">${awardStatus}</span>`;
        return html;
    }

    public static GetAwardApprovedCapacityIconClass(awardStatus: string) {
        switch (awardStatus) {
            case AwardSubmittedCapacityStatus.Pending:
                return 'text-bg-loaded';
            case AwardSubmittedCapacityStatus.Approved:
                return 'text-bg-success';
            case AwardSubmittedCapacityStatus.Rejected:
                return 'text-bg-rejected';
            default:
                return 'text-bg-draft';
        }
    }

    public static CapacityIdLinkCellRenderer(capacityUniqueId?: string, isSubmittedCapacity?: boolean) {
        const submittedIcon = (capacityUniqueId && isSubmittedCapacity) ? `<span class="badge rounded-pill rounded p-1 lh05 mr-1 text-bg-success">&nbsp;</span>` : `<span class="badge rounded-pill rounded p-1 lh05 mr-1 text-bg-success invisible">&nbsp;</span>`;
        const html = `${submittedIcon} <a class="text-primary fw-semibold cursor-pointer">${capacityUniqueId || ''}</a>`;
        return html;
    }

    public static FailedRowCellRenderer(failedRowCount?: any) {
        const html = `<span class="align-items-center d-flex gap-2">${failedRowCount || ''} <a class="text-primary fw-semibold cursor-pointer text-decoration-none"><span class="align-items-center d-flex gap-1"><span class="material-symbols-outlined text-primary">system_update_alt </span></span></a></span>`
        return html;
    }

    public static EDIRefrenceNumberCellRenderer(ediShipmentId?: string, isCreditLimitExceeded?: boolean) {
        let html = `<span  style="opacity: ${isCreditLimitExceeded ? '1' : '0'}; pointer-events: ${isCreditLimitExceeded ? 'auto' : 'none'};">
        <span class="badge rounded-pill rounded p-1 lh05 mr-1 bg-danger">&nbsp;</span></span>`;
        html += `${ediShipmentId || ''}`;
        return html;
    }
}
