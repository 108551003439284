import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AwardStatusUpdateModel } from '../../award.model';
import { SelectListModel } from '@app/core/models/select.list.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AwardService } from '../../award.service';
import { FormHelper } from '@app/core/helpers/form.helper';
import { LoginHelper } from '@app/core/helpers/login.helper';
import { ActiveInactiveStatus, AwardStatusAction } from '@app/core/constants/enums';

@Component({
  selector: 'app-award-validate',
  templateUrl: './award-validate.component.html',
  styles: [
  ]
})
export class AwardValidateComponent {
  awardStatusUpdateForm!: FormGroup;
  awardUpdateStatusRequest!: AwardStatusUpdateModel;
  awardStatusReasonList: SelectListModel[] = [];
  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: DynamicDialogRef,
    private readonly dialogConfiguration: DynamicDialogConfig,
    private readonly service: AwardService
  ) {
    this.awardStatusReasonList = this.dialogConfiguration.data?.awardStatusReasonList;
    this.initForm();
  }

  initForm(): void {
    this.awardStatusUpdateForm = this.fb.group({
      awardDetailId: new FormControl(this.dialogConfiguration.data?.awardDetailId),
      awardStatusReasonId: new FormControl(null, { validators: [Validators.required] }),
      awardStatusReasonName: new FormControl(null),
      awardStatus: new FormControl(this.dialogConfiguration.data?.awardStatusAction),
      buttonText: new FormControl(this.dialogConfiguration.data?.buttonText),
      modifiedBy: new FormControl(LoginHelper.GetUserDetail().userId),
      modifiedByUser: new FormControl(LoginHelper.GetUserDetail().userName),
      capacityDetailId: new FormControl(this.dialogConfiguration.data?.capacityDetailId),
      customerId: new FormControl(this.dialogConfiguration.data?.customerId)
    });
  }

  getControl(control: string): AbstractControl {
    return this.awardStatusUpdateForm.controls[control];
  }

  onReasonSelect(selected: SelectListModel) {
    const statusReason = this.awardStatusReasonList.find((d) => d.value === selected.value);
    if (statusReason) {
      this.getControl('awardStatusReasonName').patchValue(statusReason.text);
    }
  }

  submit(): void {
    if (this.awardStatusUpdateForm.invalid) {
      FormHelper.MarkFormGroupDirty(this.awardStatusUpdateForm);
      return;
    }
    const awardUpdateStatusRequest = this.awardStatusUpdateForm.getRawValue();
    if (awardUpdateStatusRequest.capacityDetailId) {
      this.service.updateSubmittedCapacityStatus(awardUpdateStatusRequest).subscribe((response) => {
        if (response.data) {
          this.dialogRef.close(response);
        }
      });
    } else {
      this.service.updateAwardStatus(awardUpdateStatusRequest, this.dialogConfiguration.data?.callerScreen).subscribe((response) => {
        if (response.data) {
          this.dialogRef.close(response);
        }
      });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getSubmitButtonText(): string {
    return this.dialogConfiguration.data?.awardStatusAction === AwardStatusAction.Inactivate ? ActiveInactiveStatus.Inactive : this.dialogConfiguration.data?.buttonText;
  }
}
