import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app/core/constants/api-routes';
import { Constants } from '@app/core/constants/constants';
import { Endpoint, PermissionModuleCode } from '@app/core/constants/enums';
import { ResponseModel } from '@app/core/models/api.response.model';
import { SelectListModel } from '@app/core/models/select.list.model';
import { ApiClientService } from '@app/core/services/api-client.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { Observable, map } from 'rxjs';
import { String } from 'typescript-string-operations';
import { AuditLogsListModel, CapacityGridFilterRequestModel, CapacityListModel, CarrierActivityModel, CarrierCapacityModel, CarrierCapacityRequestModel, CarrierComplianceModel, CarrierDetailModel, CarrierDocumentCofigurationModel, CarrierDocumentModel, CarrierDocumentTypeModel, CarrierEmailListModel, CarrierEntityModel, CarrierFilter, CarrierInsuranceModel, CarrierLockDetailModel, CarrierModel, CarrierModelList, CarrierPreferredLaneList, CarrierPreferredLaneModel, CarrierPreferredLaneRequestModel, CarrierPreviewDataModel, CarrierRequestModel, CarrierSalesTouchPointListModel, CarrierScorecard, CarrierScorecardKPIRule, CarrierStakeholderListModel, CarrierStakeholderRequestModel, InviteCarrierRequestModel, PreferredLaneGridFilterRequestModel, SalesCarrierFilter } from './carrier.model';
import { GridFilterRequestModel } from '@app/core/models/list-filter.model';

@Injectable()
export class CarrierService {
  carrierEndpoint!: string;
  identityEndpoint!: string;
  accountingEndpoint!: string;
  administrationEndpoint!: string;
  loadEndpoint!: string;
  constructor(
    private apiClient: ApiClientService,
    private readonly appConfigService: AppConfigService
  ) {
    this.carrierEndpoint = this.appConfigService.getEndpoint(Endpoint.Carrier);
    this.identityEndpoint = this.appConfigService.getEndpoint(Endpoint.Identity);
    this.accountingEndpoint = this.appConfigService.getEndpoint(Endpoint.Accounting);
    this.administrationEndpoint = `${this.appConfigService.getEndpoint(Endpoint.Administration)}`;
    this.loadEndpoint = `${this.appConfigService.getEndpoint(Endpoint.Load)}`;
  }

  getCarriers(payload: CarrierFilter): Observable<ResponseModel<CarrierModelList>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierList}`;
    return this.apiClient.post<CarrierFilter, ResponseModel<CarrierModelList>>(url, payload);
  }

  getPreviewData(docketNumber: string, dotNumber: string): Observable<ResponseModel<CarrierPreviewDataModel>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.getPreviewData}`;
    url = String.format(url, docketNumber, dotNumber);
    return this.apiClient.get<ResponseModel<CarrierPreviewDataModel>>(url);
  }

  create(payload: CarrierPreviewDataModel): Observable<ResponseModel<CarrierPreviewDataModel>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.createCarrier}`;
    return this.apiClient.post<CarrierPreviewDataModel, ResponseModel<CarrierPreviewDataModel>>(url, payload);
  }

  update(payload: CarrierRequestModel): Observable<ResponseModel<CarrierEntityModel>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.updateCarrier}`;
    return this.apiClient.put<CarrierRequestModel, ResponseModel<CarrierEntityModel>>(url, payload);
  }

  getCarrier(carrierId: number): Observable<ResponseModel<CarrierDetailModel>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierById}`;
    url = String.format(url, carrierId);
    return this.apiClient.get<ResponseModel<CarrierDetailModel>>(url);
  }

  getCarrierTiers(): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierTierList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  getCarrierOwnerships(): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierOwnershipList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, carrierOwnershipId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getUsers(): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getUserList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        return response;
      })
    );
  }
  getAllDepartmentUsers(departmentCode: string, userIds: string): Observable<ResponseModel<any>> {
    let url = `${this.identityEndpoint}${API_ROUTES.identity.user.getUserByDepartmentCode}`;
    url = String.format(url, (departmentCode ?? ''), (userIds ?? ''));
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        return response;
      })
    );
  }

  getCarrierContactDepartments(): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierContactDepartmentList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        return response;
      })
    );
  }

  getCarrierContactTier(): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierContactTierList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, contactTierId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getCarrierEmailList(carrierId: number): Observable<ResponseModel<CarrierEmailListModel[]>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierEmailList}`;
    url = String.format(url, carrierId);
    return this.apiClient.get<ResponseModel<CarrierEmailListModel[]>>(url).pipe(
      map(response => {
        return response;
      })
    );
  }

  inviteCarrier(payload: InviteCarrierRequestModel): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.inviteCarrier}`;
    return this.apiClient.post<InviteCarrierRequestModel, ResponseModel<CarrierEntityModel>>(url, payload);
  }


  getCarrierRejectReasonList(): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierRejectReasonList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ rejectReasonDescription: Constants.pleaseSelectDefaultText, carrierRejectReasonId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  rejectCarrier(carrierId: number, reasonId: number): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.rejectCarrier}`;
    url = String.format(url, carrierId, reasonId);
    return this.apiClient.put<any, ResponseModel<any>>(url);
  }

  getCarrierComplianceData(carrierId: number): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.getComplianceDataByCarrierId}`;
    url = String.format(url, carrierId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  syncCarrierData(carrierId: number): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.syncCarrierDataByCarrierId}`;
    url = String.format(url, carrierId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  approveCarrier(carrierId: number): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.approveCarrier}`;
    url = String.format(url, carrierId);
    return this.apiClient.put<any, ResponseModel<any>>(url);
  }

  getCarrierSelectList(payload: CarrierFilter): Observable<ResponseModel<CarrierEntityModel[]>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.carrierSelectList}`;
    return this.apiClient.post<CarrierFilter, ResponseModel<CarrierEntityModel[]>>(url, payload);
  }

  getCarrierInsurance(carrierId: number): Observable<ResponseModel<CarrierInsuranceModel>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierInsurance}`;
    url = String.format(url, carrierId ?? '');
    return this.apiClient.get<ResponseModel<CarrierInsuranceModel>>(url);
  }

  getCarriersById(carrierIds: string): Observable<ResponseModel<CarrierModel[]>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarriersById}`;
    url = String.format(url, carrierIds ?? '');
    return this.apiClient.get<ResponseModel<CarrierModel[]>>(url);
  }

  getCarrierStatusList(): Observable<ResponseModel<any[]>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierStatusList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  getCarrierDNUReason(): Observable<ResponseModel<any[]>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierDNUReason}`;
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        response.data.unshift({
          reasonCode: Constants.pleaseSelectDefaultText,
          description: Constants.pleaseSelectDefaultText, reasonId: null
        });
        return response;
      })
    );
  }

  markDNUActiveCarrierStatus(carrierId: number, status: number, module: string, reasonId = 0): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${module === PermissionModuleCode.CarrierProfile ? API_ROUTES.carrier.markDNUActiveCarrierStatusFromProfile : API_ROUTES.carrier.markDNUActiveCarrierStatus}`;
    url = String.format(url, carrierId, status, reasonId);
    return this.apiClient.put<any, ResponseModel<any>>(url);
  }

  lockCarrier(carrierId: number): Observable<ResponseModel<CarrierLockDetailModel>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.lockCarrier}`;
    url = String.format(url, carrierId);
    return this.apiClient.post<undefined, ResponseModel<CarrierLockDetailModel>>(url);
  }

  unLockCarrier(carrierId: number, isBroadcastOnly: boolean, unlockTimeInSec: number, lockedBy: number): Observable<ResponseModel<CarrierLockDetailModel>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.unlockCarrier}`;
    url = String.format(url, carrierId, isBroadcastOnly, unlockTimeInSec, isBroadcastOnly ? false : true, lockedBy);
    return this.apiClient.delete<ResponseModel<CarrierLockDetailModel>>(url);
  }

  getCarrierLockDetail(carrierId: number): Observable<ResponseModel<CarrierLockDetailModel>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierLockDetails}`;
    url = String.format(url, carrierId);

    return this.apiClient.get<ResponseModel<CarrierLockDetailModel>>(url);
  }

  getFactoringCompanyList(): Observable<ResponseModel<any[]>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.carrier.factoringCompany.getFactoringCompanyList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  getFactoringCompanyPaymentModeList(): Observable<ResponseModel<any[]>> {
    const url = `${this.accountingEndpoint}${API_ROUTES.carrier.factoringCompany.getFactoringCompanyPaymentModeList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  getCarrierPaymentTermList(): Observable<ResponseModel<any[]>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.factoringCompany.getCarrierPaymentTermList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  checkCarrierExists(carrierName: string): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.checkCarrierExists}`;
    url = String.format(url, encodeURIComponent(carrierName));
    return this.apiClient.get<ResponseModel<any[]>>(url);
  }

  getEquipmentSelectList(equipmentId?: number): Observable<ResponseModel<any[]>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.equipment.getEquipmentSelectList}`;
    url = url + (equipmentId ? `?equipmentId=${equipmentId}` : ``);
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ name: Constants.pleaseSelectDefaultText, equipmentId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getRadiusSelectList(loadRadiusId?: number): Observable<ResponseModel<any[]>> {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadDetail.getLoadRadiusSelectList}`;
    url = url + (loadRadiusId ? `?loadRadiusId=${loadRadiusId}` : ``);
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList.unshift({ loadRadiusValue: Constants.pleaseSelectDefaultText, loadRadiusId: null });
        response.data = selectList;
        return response;
      })
    );
  }

  getCarrierCapacityList(payload: CapacityGridFilterRequestModel): Observable<ResponseModel<CapacityListModel>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierCapacity}`;
    return this.apiClient.post<CapacityGridFilterRequestModel, ResponseModel<CapacityListModel>>(url, payload);
  }

  getPreferredLaneList(payload: PreferredLaneGridFilterRequestModel): Observable<ResponseModel<CarrierPreferredLaneList>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.preferredLane.getPreferredLaneCapacity}`;
    return this.apiClient.post<PreferredLaneGridFilterRequestModel, ResponseModel<CarrierPreferredLaneList>>(url, payload);
  }
  checkDuplicateCarrierCapacity(payload: CarrierCapacityRequestModel): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.checkDuplicateCarrierCapacity}`;
    return this.apiClient.post<CarrierCapacityRequestModel, ResponseModel<any>>(url, payload);
  }

  saveCarrierCapacity(payload: CarrierCapacityRequestModel, isEdit: boolean): Observable<ResponseModel<any>> {
    let url = '';
    if (isEdit) {
      url = `${this.carrierEndpoint}${API_ROUTES.carrier.updateCarrierCapacity}`;
      return this.apiClient.put<CarrierCapacityRequestModel, ResponseModel<CarrierCapacityModel>>(url, payload);
    }
    else {
      url = `${this.carrierEndpoint}${API_ROUTES.carrier.createCarrierCapacity}`;
      return this.apiClient.post<CarrierCapacityRequestModel, ResponseModel<CarrierCapacityModel>>(url, payload);
    }
  }

  saveCarrierPreferredLane(payload: CarrierPreferredLaneRequestModel, isEdit: boolean): Observable<ResponseModel<any>> {
    let url = '';
    if (isEdit) {
      url = `${this.carrierEndpoint}${API_ROUTES.carrier.preferredLane.updateCarrierPreferredLane}`;
      return this.apiClient.put<CarrierPreferredLaneRequestModel, ResponseModel<CarrierPreferredLaneRequestModel>>(url, payload);
    }
    else {
      url = `${this.carrierEndpoint}${API_ROUTES.carrier.preferredLane.createCarrierPreferredLane}`;
      return this.apiClient.post<CarrierPreferredLaneRequestModel, ResponseModel<CarrierPreferredLaneRequestModel>>(url, payload);
    }
  }



  deleteCarrierCapacity(carrierCapacityId: number, carrierId: number): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.deleteCarrierCapacity}`;
    url = String.format(url, carrierCapacityId, carrierId);
    return this.apiClient.delete<ResponseModel<any>>(url);
  }

  getCarrierDocumentConfiguration(): Observable<ResponseModel<CarrierDocumentCofigurationModel>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.document.getCarrierDocumentConfiguration}`;
    return this.apiClient.get<ResponseModel<CarrierDocumentCofigurationModel>>(url);
  }

  getCarrierDocumentList(carrierId: number): Observable<ResponseModel<CarrierDocumentModel[]>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.document.getCarrierDocumentList}`;
    url = String.format(url, carrierId);
    return this.apiClient.get<ResponseModel<CarrierDocumentModel[]>>(url);
  }

  getCarrierDocumentTypeList(): Observable<ResponseModel<CarrierDocumentTypeModel[]>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.document.getCarrierDocumentTypeList}`;
    return this.apiClient.get<ResponseModel<CarrierDocumentTypeModel[]>>(url);
  }

  deleteCarrierDocument(documentId: number, carrierId: number): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.document.deleteCarrierDocument}`;
    url = String.format(url, documentId, carrierId);
    return this.apiClient.delete<ResponseModel<any>>(url);
  }

  uploadCarrierDocument(payload: any): Observable<any> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.document.uploadCarrierDocument}`;
    return this.apiClient.post<any, any>(url, payload);
  }

  downloadCarrierDocument(documentId: number): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.document.downloadCarrierDocument}`;
    url = String.format(url, documentId);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  getActivitySelectList(): Observable<ResponseModel<SelectListModel[]>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getActivityTypeSelectList}`;
    return this.apiClient.get<ResponseModel<SelectListModel[]>>(url).pipe(
      map(response => {
        const selectList = response.data;
        selectList?.unshift({ text: Constants.pleaseSelectDefaultText, value: null });
        response.data = selectList;
        return response;
      })
    );
  }

  createCarrierActivity(payload: CarrierActivityModel): Observable<ResponseModel<CarrierActivityModel>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.carrierActivity.createCarrierActivity}`;
    return this.apiClient.post<CarrierActivityModel, ResponseModel<CarrierActivityModel>>(url, payload);
  }

  getCarrierActivities(carrierId: number): Observable<ResponseModel<CarrierActivityModel[]>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.carrierActivity.getCarrierActivityList}`;
    url = String.format(url, carrierId);
    return this.apiClient.get<ResponseModel<CarrierActivityModel[]>>(url);
  }

  updateStakeholder(payload: CarrierStakeholderRequestModel): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.stakeholder.updateStakeholder}`;
    return this.apiClient.put<CarrierStakeholderRequestModel, ResponseModel<any>>(url, payload);
  }

  getCurrentDateTimeByCityState(city: string, state: string): Observable<ResponseModel<Date>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.zipCode.getCurrentDateTimeByCityState}`;
    url = String.format(url, city, state);
    return this.apiClient.get<ResponseModel<Date>>(url);
  }

  getCTDateTimeByCityState(city: string, state: string, readyDateTime: string): Observable<ResponseModel<Date>> {
    let url = `${this.administrationEndpoint}${API_ROUTES.administration.zipCode.getCTDateTimeByCityState}`;
    url = String.format(url, city, state, readyDateTime);
    return this.apiClient.get<ResponseModel<Date>>(url);
  }

  getCarrierAuditLogs(payload: CarrierFilter): Observable<ResponseModel<AuditLogsListModel>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierAuditLogList}`;
    return this.apiClient.post<CarrierFilter, ResponseModel<AuditLogsListModel>>(url, payload);
  }
  getSalesTouchPoint(payload: SalesCarrierFilter): Observable<ResponseModel<CarrierSalesTouchPointListModel>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getSalesTouchPoint}`;
    return this.apiClient.post<SalesCarrierFilter, ResponseModel<CarrierSalesTouchPointListModel>>(url, payload);
  }

  getCarrierComplianceDocument(documentPath: string): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierComplianceDocument}`;
    url = String.format(url, documentPath);
    return this.apiClient.get<ResponseModel<any>>(url);
  }

  addActivityLog(carrierId: number, tabName: string): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.addActivityLog}`;
    url = String.format(url, carrierId, encodeURIComponent(tabName));
    return this.apiClient.post<any, any>(url);
  }

  addDocumentLog(carrierId: number, documentId: number): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.document.addDocumentLog}`;
    url = String.format(url, carrierId, documentId);
    return this.apiClient.post<any, any>(url);
  }

  getCarrierServices(): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierServiceList}`;
    return this.apiClient.get<ResponseModel<any[]>>(url).pipe(
      map(response => {
        return response;
      })
    );
  }
  deleteCarrierActivity(payload: CarrierActivityModel): Observable<ResponseModel<CarrierActivityModel>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.carrierActivity.deleteCarrierActivity}`;
    url = String.format(url, payload.carrierActivityId, payload.carrierId);
    return this.apiClient.delete<ResponseModel<CarrierActivityModel>>(url);
  }

  updateCarrierActivity(payload: CarrierActivityModel): Observable<ResponseModel<CarrierActivityModel>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.carrierActivity.updateCarrierActivity}`;
    return this.apiClient.put<CarrierActivityModel, ResponseModel<CarrierActivityModel>>(url, payload);
  }

  manuallyOnboardCarrier(carrierId: number, isManuallyOnboard: boolean): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.manuallyOnboard}`;
    url = String.format(url, carrierId, isManuallyOnboard);
    return this.apiClient.post<any, any>(url);
  }

  checkDuplicatePreferredLane(payload: CarrierPreferredLaneRequestModel): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.preferredLane.checkDuplicatePreferredLane}`;
    return this.apiClient.post<CarrierPreferredLaneRequestModel, ResponseModel<any>>(url, payload);
  }

  updatePreferredLaneStatus(carrierPreferredLaneId: number, carrierId: number, isActive: boolean): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.preferredLane.updatePreferredLaneStatus}`;
    url = String.format(url, carrierPreferredLaneId, carrierId, isActive);
    return this.apiClient.put<CarrierPreferredLaneRequestModel, ResponseModel<any>>(url);
  }

  renewCarrierPreferredLane(carrierPreferredLaneId: number, carrierId: number, validityDate: string, validityDateCT: string): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.preferredLane.renewCarrierPreferredLane}`;
    url = String.format(url, carrierPreferredLaneId, carrierId, validityDate, validityDateCT);
    return this.apiClient.put<CarrierPreferredLaneRequestModel, ResponseModel<any>>(url);
  }

  getCarrierScoreCard(carrierId: number): Observable<ResponseModel<CarrierScorecard>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierScoreCard}`;
    url = String.format(url, carrierId);
    return this.apiClient.get<ResponseModel<CarrierScorecard>>(url);
  }

  getCarrierScoreCardKPIRule(): Observable<ResponseModel<CarrierScorecardKPIRule>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierScoreCardKPIRule}`;
    return this.apiClient.get<ResponseModel<CarrierScorecardKPIRule>>(url);
  }

  getCarrierStakeholder(carrierId: number): Observable<ResponseModel<CarrierStakeholderListModel>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.stakeholder.getStakeholder}`;
    url = String.format(url, carrierId);
    return this.apiClient.get<ResponseModel<CarrierStakeholderListModel>>(url);
  }

  uploadFuelSurchargeDocument(payload: any): Observable<any> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.uploadFuelSurchargeDocument}`;
    return this.apiClient.post<any, any>(url, payload);
  }

  downloadFuelSurchargeDocument(documentId: number, carrierId: number): Observable<any> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.downloadFuelSurchargeDocument}`;
    url = String.format(url, documentId, carrierId);
    return this.apiClient.get<any>(url);
  }
  updateManageFuelSurcharge(carrierId: number, canManageFuelSurcharge: boolean): Observable<any> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.updateManageFuelSurcharge}`;
    url = String.format(url, carrierId, canManageFuelSurcharge);
    return this.apiClient.put<any, any>(url);
  }
  getCarrierFuelSurchargeList(carrierId: number): Observable<any> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierFuelSurchargeList}`;
    url = String.format(url, carrierId);
    return this.apiClient.get<any>(url);
  }

  exportCarrierListingToCSV(filterRequest: CarrierFilter): Observable<ResponseModel<any>> {
    const url = `${this.carrierEndpoint}${API_ROUTES.carrier.sendExportCsvFilePayloadToServiceBus}`;
    filterRequest.pageSize = Constants.maxPageSizeForExportListing;
    filterRequest.pageNumber = 1;
    filterRequest.pageSize = Constants.maxPageSizeForExportListing;
    return this.apiClient.post<CarrierFilter, ResponseModel<any>>(url, filterRequest);
  }

  checkCarrierRepCountForLead(carrierRepId: number): Observable<any> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.checkCarrierRepCountForLead}`;
    url = String.format(url, carrierRepId);
    return this.apiClient.get(url);
  }

  getCarrierOwnershipDetail(carrierId: number): Observable<ResponseModel<any>> {
    let url = `${this.carrierEndpoint}${API_ROUTES.carrier.getCarrierOwnershipDetail}`;
    url = String.format(url, carrierId);
    return this.apiClient.get(url);
    }

  getTruckMatchList(filterRequest : GridFilterRequestModel, equipmentId: number, callerScreen: string) {
    let url = `${this.loadEndpoint}${API_ROUTES.load.loadMatch.getLoadMatchList}`
    url = String.format(url, equipmentId, callerScreen);
    return this.apiClient.post<GridFilterRequestModel, ResponseModel<any>>(url, filterRequest);
  }
}

