<a *ngIf="!displayOnGrid" class="text-primary cursor-pointer ellipsis w-90" (click)="userClick($event)"
    pTooltip="{{userName}}">{{userName}}</a>
<a *ngIf="params?.data && params?.data[params?.userName] && displayOnGrid && !params?.customerList && !params?.carrierList && !isSystemUser()"
    class="text-primary fw-semibold cursor-pointer ellipsis w-90" pTooltip="{{params?.data &&
    params?.data[params?.userName]}}" (click)="userClick($event)">{{params?.data &&
    params?.data[params?.userName]}}</a>
<span *ngIf="!params?.data || !params?.data[params?.userName]">-</span>
<a *ngIf="displayOnGrid && params?.customerList && !isSystemUser()"
    class="text-primary fw-semibold cursor-pointer ellipsis w-90" (click)="userClick($event)" pTooltip="{{params?.data &&
    params?.data?.customerStakeholder[params?.userName]}}">{{params?.data &&
    params?.data?.customerStakeholder[params?.userName]}}</a>
<a *ngIf="displayOnGrid && params?.carrierList && !isSystemUser()"
    class="text-primary fw-semibold cursor-pointer ellipsis w-90" pTooltip="{{params?.data &&
    params?.data[params?.userName]}}" (click)="userClick($event)">{{getCarrierRepName()}}</a>
<span class="ellipsis w-90" *ngIf="displayOnGrid && isSystemUser()" pTooltip="{{params?.data &&
    params?.data[params?.userName]}}">{{params?.data &&
    params?.data[params?.userName]}}</span>
<span class="ellipsis w-90" *ngIf="!displayOnGrid && isSystemUser()" pTooltip="{{userName}}">{{userName}}</span>
<p-overlayPanel #op styleClass="overlay-panel-user-progress">
    <ng-template pTemplate="content">
        <div class="card card-user-progress" *ngIf="userSalesData">
            <div class="d-flex gap-3 border-bottom border-lightestgray p-3 align-items-center">
                <span class="initial">{{userSalesData.name | nameInitial}}</span>
                <div class="flex-grow-1">
                    <h1 class="text-primary">{{userSalesData.name}}</h1>
                    <div class="d-flex gap-3">
                        <p class="mb-0 pe-3 border-right-1 border-lightgray">{{userSalesData.userName}}</p>
                        <p class="mb-0 pe-3 border-right-1 border-lightgray">{{userSalesData.department}}</p>
                        <p class="mb-0 pe-3 border-right-1 border-lightgray">{{userSalesData.role}}</p>
                        <p class="mb-0" *ngIf="userSalesData.salesUserScoreCardId">{{userSalesData.userPhone |
                            maskPhoneNumber}}</p>
                        <p class="mb-0" *ngIf="userSalesData.procurementUserScoreCardId">{{userSalesData.userPhone |
                            maskPhoneNumber}}</p>
                        <p class="mb-0" *ngIf="userSalesData.trackingUserScoreCardId">{{userSalesData.userPhone |
                            maskPhoneNumber}}</p>
                    </div>
                </div>
                <h4 [hidden]="true" class="circle">{{userSalesData.count}}</h4>
            </div>
            <div class="p-3">
                <div class="row" *ngIf="userSalesData.salesUserScoreCardId>0">
                    <div class="col-md-6">
                        <div class="card text-center">
                            <h5 class="mb-3">Total Volume Revenue ($)</h5>
                            <div class="d-flex gap-3 justify-content-between">
                                <div>
                                    <h5>MTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.revenueMonthToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                                <div>
                                    <h5>QTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.revenueQuarterToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                                <div>
                                    <h5>YTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.revenueYearToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card text-center">
                            <h5 class="mb-3">Gross Margin ($)</h5>
                            <div class="d-flex gap-3 justify-content-between">
                                <div>
                                    <h5>MTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.marginMonthToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                                <div>
                                    <h5>QTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.marginQuarterToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                                <div>
                                    <h5>YTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.marginYearToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="userSalesData.salesUserScoreCardId === -1">
                    <div class="col-md-6">
                        <div class="card text-center">
                            <h5 class="mb-3">Total Volume Revenue ($)</h5>
                            <div class="d-flex gap-3 justify-content-between">
                                <div>
                                    <h5>MTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                                <div>
                                    <h5>QTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                                <div>
                                    <h5>YTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card text-center">
                            <h5 class="mb-3">Gross Margin ($)</h5>
                            <div class="d-flex gap-3 justify-content-between">
                                <div>
                                    <h5>MTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                                <div>
                                    <h5>QTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                                <div>
                                    <h5>YTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="userSalesData.procurementUserScoreCardId>0">
                    <div class="col-md-6">
                        <div class="card text-center">
                            <h5 class="mb-3">Total Volume Revenue ($)</h5>
                            <div class="d-flex gap-3 justify-content-between">
                                <div>
                                    <h5>MTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.volumnMonthToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                                <div>
                                    <h5>QTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.volumnQuarterToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                                <div>
                                    <h5>YTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.volumnYearToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card text-center">
                            <h5 class="mb-3">Gross Margin ($)</h5>
                            <div class="d-flex gap-3 justify-content-between">
                                <div>
                                    <h5>MTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.grossMarginMonthToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                                <div>
                                    <h5>QTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.grossMarginQuarterToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                                <div>
                                    <h5>YTD</h5>
                                    <h3 class="text-primary mb-0">
                                        {{userSalesData.grossMarginYearToDate?.toLocaleString()||'-'}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="userSalesData.procurementUserScoreCardId===-1">
                    <div class="col-md-6">
                        <div class="card text-center">
                            <h5 class="mb-3">Total Volume Revenue ($)</h5>
                            <div class="d-flex gap-3 justify-content-between">
                                <div>
                                    <h5>MTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                                <div>
                                    <h5>QTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                                <div>
                                    <h5>YTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card text-center">
                            <h5 class="mb-3">Gross Margin ($)</h5>
                            <div class="d-flex gap-3 justify-content-between">
                                <div>
                                    <h5>MTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                                <div>
                                    <h5>QTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                                <div>
                                    <h5>YTD</h5>
                                    <h3 class="text-primary mb-0">
                                        -</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-3" *ngIf="userSalesData.salesUserScoreCardId>0">
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-lightgray">Title</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">MTD </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">QTD </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">YTD </h5>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">RAMP Volume Attained (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.monthToDateRAMPVolumnAttained||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.quarterToDateRAMPVolumnAttained||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.yearToDateRAMPVolumnAttained||'-'}}
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">RAMP Revenue Attained (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.monthToDateRAMPRevenueAttained||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.quarterToDateRAMPRevenueAttained||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.yearToDateRAMPRevenueAttained||'-'}}
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">RAMP Margin Attained (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.monthToDateRAMPMarginAttained||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.quarterToDateRAMPMarginAttained||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.yearToDateRAMPMarginAttained||'-'}}
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Average Volume (per day)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.monthToDateAverageVolumn?.toLocaleString()||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.quarterToDateAverageVolumn?.toLocaleString()||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.yearToDateAverageVolumn?.toLocaleString()||'-'}} </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Average Revenue ($) (per day)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.monthToDateAverageRevenue?.toLocaleString()||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.quarterToDateAverageRevenue?.toLocaleString()||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.yearToDateAverageRevenue?.toLocaleString()||'-'}} </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Average Margin ($)(per day)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.monthToDateAverageMargin?.toLocaleString()||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.quarterToDateAverageMargin?.toLocaleString()||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.yearToDateAverageMargin?.toLocaleString()||'-'}} </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Spot vs Fixed</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.monthToDateSpotFixLoad?.toLocaleString()||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.quarterToDateSpotFixLoad?.toLocaleString()||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.yearToDateSpotFixLoad?.toLocaleString()||'-'}} </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">New Customer</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.monthToDateNewCustomerCount?.toLocaleString()||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.quarterToDateNewCustomerCount?.toLocaleString()||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.yearToDateNewCustomerCount?.toLocaleString()||'-'}} </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Total # of active accounts</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.monthToDateActiveAccounts?.toLocaleString()||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.quarterToDateActiveAccounts?.toLocaleString()||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.yearToDateActiveAccounts?.toLocaleString()||'-'}} </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Customer Dropped</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.monthToDateCustomerDropped?.toLocaleString()||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.quarterToDateCustomerDropped?.toLocaleString()||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.yearToDateCustomerDropped?.toLocaleString()||'-'}} </h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card mt-3" *ngIf="userSalesData.salesUserScoreCardId===-1">
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-lightgray">Title</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">MTD </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">QTD </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">YTD </h5>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">RAMP Volume Attained (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">RAMP Revenue Attained (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">RAMP Margin Attained (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Average Volume (per day)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        -</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        -</h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Average Revenue ($) (per day)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        -
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Average Margin ($)(per day)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Spot vs Fixed</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">New Customer</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        -
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        -
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        -</h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Total # of active accounts</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        -
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Customer Dropped</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        -
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        - </h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card mt-3" *ngIf="userSalesData.procurementUserScoreCardId>0">
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-lightgray">Title</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">MTD </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">QTD </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">YTD </h5>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Average Margin ($)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.averageMarginMonthToDate?.toLocaleString()||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.averageMarginQuarterToDate?.toLocaleString()||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        {{userSalesData.averageMarginYearToDate?.toLocaleString()||'-'}}
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">On Time Pick (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.onTimePickUpMonthToDate||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.onTimePickUpMonthToQuarter||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.onTimePickUpMonthToYear||'-'}}
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">On Time Delivery (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.onTimeDeliveryMonthToDate||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.onTimeDeliveryMonthToQuarter||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.onTimeDeliveryMonthToYear||'-'}}
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">E-Track Freight (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.eTrackFreightMonthToDate||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.eTrackFreightYearToQuarter||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.onETrackFreightMonthToYear||'-'}}
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Carrier Utilization (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.carrierUtilizationMonthToDate||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.carrierUtilizationQuarterToDate||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.carrierUtilizationYearToDate||'-'}}
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Regional Freight (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.regionalFreightMonthToDate||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.regionalFreightQuarterToDate||'-'}}
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.regionalFreightYearToDate||'-'}} </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Prebook (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.preBookMonthToDate||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.preBookQuarterToDate||'-'}} </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">{{userSalesData.preBookYearToDate||'-'}} </h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card mt-3" *ngIf="userSalesData.procurementUserScoreCardId===-1">
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-lightgray">Title</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">MTD </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">QTD </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-lightgray">YTD </h5>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Average Margin ($)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        -
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        -
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">
                                        -
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">On Time Pick (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">On Time Delivery (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">E-Track Freight (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Carrier Utilization (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Regional Freight (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">- </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">-
                                    </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">- </h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 class="p-1 text-primary">Prebook (%)</h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">- </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">- </h5>
                                </td>
                                <td>
                                    <h5 class="p-1 text-primary">- </h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>