<div *ngIf="userNotification"
    class="d-flex gap-3 border-bottom border-body p-4 align-items-center {{userNotification.isRead ? '' :'bg-grid'}}">
    <div class="notification-icon-wrapper icon-notification-email flex-grow-0">
        <span class="material-symbols-outlined">
            notification_important
        </span>
    </div>
    <div class="notification-content w-16rem">
        <h5 class="mb-1 ellipsis d-block  {{userNotification.isRead ? '' :'fw-bold'}}"
            pTooltip="{{userNotification.title}}" tooltipPosition="bottom">
            {{userNotification.title || '&nbsp;'}}</h5>
        <p class="text-lightgray mb-1 ellipsis d-block lh-base" pTooltip="{{userNotification.description}}"
            tooltipPosition="bottom">
            {{userNotification.description || '&nbsp;'}}
        </p>
        <p class="text-loaded tiny mb-0 lh-base">
            {{userNotification.createdDate || '&nbsp;'}}
        </p>
    </div>
    <span pTooltip="Mark as read" tooltipPosition="left" *ngIf="!userNotification.isRead"
        class="badge rounded-pill rounded p-1 lh05 mr-1 bg-open">
        &nbsp;
    </span>
</div>